import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsRoutingModule } from './results-routing.module';
import { ResultsComponent } from './components/results/results.component';
import { LoungesComponent } from './components/lounges/lounge.component';
import { StartupModule } from '../startup/startup.module';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ValidateErrorsComponent } from './components/validate-errors/validate-errors.component';
import { SearchModule } from '../search-module/search-module.module';
import { LoungeDetailsComponent } from './components/lounges/lounge-details/lounge-details.component';
import { ParkingComponent } from './components/parking/parking.component';
import { ParkingDetailsComponent } from './components/parking/parking-details/parking-details.component';
import { ParkingListViewComponent } from './components/parking/parking-list-view/parking-list-view.component';
import { ParkingMapViewComponent } from './components/parking/parking-map-view/parking-map-view.component';
import { TabsComponent } from './components/shared/tabs/tabs.component';
import { HotelsComponent } from './components/hotel/hotels/hotels.component';
import { ItemHotelsComponent } from './components/hotel/item-hotels/item-hotels.component';
import { FilterHotelsComponent } from './components/hotel/filter-hotels/filter-hotels.component';
import { MapHotelsComponent } from './components/hotel/map-hotels/map-hotels.component';
import { TaxisComponent } from './components/taxis/taxis.component';
import { SortingToggleComponent } from './components/taxis/sorting-toggle/sorting-toggle.component';
import { RatesHotelComponent } from './components/hotel/rates-hotel/rates-hotel.component';
import { WarningModalComponent } from './components/hotel/rates-hotel/warning-modal/warning-modal.component';
import { WarningRemoveModalComponent } from './components/hotel/rates-hotel/warning-remove-modal/warning-remove-modal.component';
import { CarsComponent } from './components/cars/cars.component';
import { RailResultsComponent } from './components/rail/rail-results.component';
import { ModalLoungeImagesComponent } from './components/lounges/modal-lounge-images/modal-lounge-images.component';
import { GalleryComponent } from './components/shared/gallery/gallery.component';
import { TooltipsComponent } from './components/shared/tooltips/tooltips.component';
import { SortComponent } from './components/shared/sort/sort.component';
import { ModalIframeComponent } from './components/shared/modal-iframe/modal-iframe.component';
import { SearchToggleComponent } from './components/shared/search-toggle/search-toggle.component';
import { HotelFacilitiesComponent } from './components/hotel/item-hotels/hotel-facilities/hotel-facilities.component';
import { WellbeingComponent } from '../results-module/components/wellbeing/wellbeing.component';
import { WellbeingChosenRailComponent } from './components/wellbeing/wellbeing-chosen-rail/wellbeing-chosen-rail.component';
///
import { FlightsComponent } from './components/flights/flights.component';
import { FlightResultsComponent } from './components/flights/flight-results/flight-results.component';
import { FlightsSearchTabsComponent } from './components/flights/flight-search-tabs/flight-search-tabs.component';
import { ItemFlightsComponent } from './components/flights/item-flights/item-flights.component';
import { FareSeatHeaderComponent } from './components/flights/fare-seat-header/fare-seat-header.component';
import { FilterFlightsComponent } from './components/flights/filter-flights/filter-flights.component';

import { IrlComponent } from './components/irl-results/irl/irl.component';
import { IrlResultsComponent } from './components/irl-results/irl-results.component';
import { IrlListComponent } from './components/irl-results/irl-list/irl-list.component';
import { IrlItemDetailComponent } from './components/irl-results/irl-list-item-detail/irl-list-item-detail.component';
import { MatSelectModule } from '@angular/material/select';
import { RoutehappyAmenitiesComponent } from './components/flights/routehappy-amenities/routehappy-amenities.component';
import { RoutehappyComponent } from './components/flights/routehappy/routehappy.component';
import { FerryComponent } from './components/ferry/ferry.component';
import { FlightTypeTicketsOldComponent } from './components/flights/flight-journey-detail-old/flight-type-tickets/flight-type-tickets.component';
import { EurostarComponent } from './components/eurostar/eurostar.component';
import { EurostarListComponent } from './components/eurostar/eurostar-list/eurostar-list.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { PriceBreakdownDialogComponent } from './components/hotel/rates-hotel/price-breakdown-dialog/price-breakdown-dialog.component';
import { RailComponent } from './components/rail/rail/rail.component';
import { CheapestButtonComponent } from './components/rail/cheapest-button/cheapest-button.component';
import { FlightSeatMapTabComponent } from './components/flights/flight-extras-dialog/flight-seat-map-tab/flight-seat-map-tab.component';
import { FlightExtrasDialogComponent } from './components/flights/flight-extras-dialog/flight-extras-dialog.component';
import { FlightSeatMapComponent } from './components/flights/flight-extras-dialog/flight-seat-map/flight-seat-map.component';
import { FlightExtrasComponent } from './components/flights/flight-extras-dialog/flight-extras/flight-extras.component';
import { ScrollingModule as ScrollingModuleExperimental } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RailTicketsDialogComponent } from './components/rail/rail-tickets-dialog/rail-tickets-dialog.component';
import { SeePhotosDialogComponent } from './components/hotel/item-hotels/hotel-photos-dialog/hotel-photos-dialog.component';
import { HotelInfoDialogComponent } from './components/hotel/item-hotels/hotel-info-dialog/hotel-info-dialog.component';
import { FastTrackComponent } from './components/fast-track/fast-track.component';
import { ModalFastTrackImagesComponent } from './components/fast-track/modal-fast-track-images/modal-fast-track-images.component';
import { FastTrackDetailsComponent } from './components/fast-track/fast-track-details/fast-track-details.component';
import { CarbonComparisonComponent } from '../vendor/components/carbon-comparison/carbon-comparison.component';
import { CarbonComparisonEurostarComponent } from '../vendor/components/carbon-comparison-eurostar/carbon-comparison-eurostar.component';
import { FlightExtrasRecalculateComponent } from './components/flights/flight-extras-dialog/flight-extras-recalculate/flight-extras-recalculate.component';
import { FlightTypeTicketsComponent } from './components/flights/flight-journey-detail/flight-type-tickets/flight-type-tickets.component';
import { TrainlineEuResultsComponent } from './components/rail/trainline-eu-results/trainline-eu-results.component';
import { RailJourneySegmentsComponent } from './components/rail/rail-journey-segments/rail-journey-segments.component';
import { BreakfastIconComponent } from './components/hotel/breakfast-icon/breakfast-icon.component';
const components = [
  // ItemDetailComponent,
  CarsComponent,
  EurostarComponent,
  EurostarListComponent,
  FareSeatHeaderComponent,
  FilterFlightsComponent,
  FilterHotelsComponent,
  FerryComponent,
  FlightResultsComponent,
  FlightsComponent,
  FlightsSearchTabsComponent,
  FlightTypeTicketsComponent,
  FlightTypeTicketsOldComponent,
  HotelFacilitiesComponent,
  HotelsComponent,
  IrlComponent,
  IrlItemDetailComponent,
  IrlListComponent,
  IrlResultsComponent,
  ItemFlightsComponent,
  ItemHotelsComponent,
  LoungeDetailsComponent,
  LoungesComponent,
  MapHotelsComponent,
  ModalIframeComponent,
  ModalLoungeImagesComponent,
  GalleryComponent,
  ModalFastTrackImagesComponent,
  FastTrackDetailsComponent,
  NoResultsComponent,
  ParkingComponent,
  ParkingDetailsComponent,
  ParkingListViewComponent,
  ParkingMapViewComponent,
  PriceBreakdownDialogComponent,
  CheapestButtonComponent,
  RailComponent,
  RailResultsComponent,
  RatesHotelComponent,
  ResultsComponent,
  RoutehappyAmenitiesComponent,
  RoutehappyComponent,
  SearchToggleComponent,
  SelectFilterComponent,
  SortComponent,
  SortingToggleComponent,
  TabsComponent,
  TaxisComponent,
  TooltipsComponent,
  ValidateErrorsComponent,
  WarningModalComponent,
  WarningRemoveModalComponent,
  WellbeingChosenRailComponent,
  WellbeingComponent,
  FilterFlightsComponent,
  IrlComponent,
  IrlResultsComponent,
  IrlListComponent,
  IrlItemDetailComponent,
  SelectFilterComponent,
  FlightExtrasDialogComponent,
  FlightSeatMapComponent,
  FlightSeatMapTabComponent,
  FlightExtrasComponent,
  RailTicketsDialogComponent,
  SeePhotosDialogComponent,
  HotelInfoDialogComponent,
  FastTrackComponent,
  CarbonComparisonComponent,
  CarbonComparisonEurostarComponent,
  FlightExtrasRecalculateComponent,
  TrainlineEuResultsComponent,
  RailJourneySegmentsComponent,
  BreakfastIconComponent
];
@NgModule({
  imports: [
    CommonModule,
    ResultsRoutingModule,
    SearchModule,
    StartupModule,
    MatSelectModule,
    ScrollingModule,
    ScrollingModuleExperimental
  ],
  exports: components,
  declarations: components,
  providers: [
  ]
})
export class ResultsModule { }

