<ng-template #rt let-r="result" let-t="term">
    {{ r.destination }} ({{ r.gateway.toUpperCase() }})
</ng-template>
<ng-container
    *ngIf="{
        terminals: searchParameters.terminals | async,
        isArrivalLoading: isArrivalLoading | async
    } as data">
    <div class="parking-search-container">
        <div *ngIf="searchParameters?.clonedBasketItemDetails?.name">
            <app-cloned-details [name]="searchParameters?.clonedBasketItemDetails?.name"></app-cloned-details>
        </div>
        <div class="half-container">
            <div class="half-block">
                <div class="half-container">
                    <div class="half-block">
                        <label
                            class="header-label"
                            id="terminallabel"
                            i18n="@@pnlSearchCountry"
                            for="lounge-select-country"
                            >Country:</label
                        >
                        <select
                            [(ngModel)]="searchParameters.countryCode"
                            class="select-box select-box-right terminals"
                            id="lounge-select-country">
                            <option
                                *ngFor="let country of searchParameters.countriesWithLounges"
                                [ngValue]="country.cCode">
                                {{ country.cName | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="half-block">
                        <label
                            class="header-label"
                            id="airportlabel"
                            for="airport"
                            i18n="@@pnlSearchAirport"
                            >Airport</label
                        >:
                        <i class="ico-flight combo-box-icon" aria-hidden="true"></i>
                        <div class="flex-row-container">
                            <input
                                #inputAirport="ngbTypeahead"
                                autocomplete="off"
                                type="text"
                                id="airport"
                                i18n-placeholder
                                placeholder="Enter an airport"
                                onClick="this.select(); "
                                name="travellingFrom"
                                [inputFormatter]="formatAirports"
                                [ngbTypeahead]="getAirports"
                                [(ngModel)]="searchParameters.locationCode"
                                [ngClass]='{"error-box": searchParameters.validationMessages?.includes("You must select airport first.")}'
                                [resultTemplate]="rt"
                                (focus)="airportsFocus$.next($event.target.value)"
                                (click)="airportsClick$.next($event.target.value)"
                                class="select-box"
                                aria-labelledby="airportlabel"
                                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                                (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
                            <i
                                *ngIf="loadingAirports"
                                class="button_spinner input-loading-spinner-left"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="half-block right-block">
                <div class="half-container">
                    <div class="half-block">
                        <label
                            class="header-label"
                            id="terminallabel"
                            i18n="@@pnlSearchTerminal"
                            for="pnl_terminal"
                            >Terminal:</label
                        >
                        <p *ngIf="data.terminals.length === 0" i18n="@@ParkingSearchSelectAirportFirst">Select airport first</p>
                        <select
                            [(ngModel)]="searchParameters.terminal"
                            class="select-box select-box-right terminals"
                            id="lounge-select-terminal">
                            <option
                                *ngFor="let terminal of data.terminals"
                                [ngValue]="terminal.value">
                                {{ terminal.key | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="half-block">
                        <label
                            class="header-label"
                            for="Flightnumberlabel"
                            i18n="@@pnlSearchFlightNumber"
                            >Flight number:</label
                        >
                        <input
                            class="input-box input-box--no-icon"
                            i18n-placeholder
                            placeholder="Enter flight number"
                            [(ngModel)]="searchParameters.flightNumber"
                            id="Flightnumberlabel" />
                    </div>
                </div>
            </div>
        </div>
        <div class="half-container">
            <div class="half-block">
                <div class="flex-row-container"
                [ngClass]='{"error-box": searchParameters.validationMessages?.includes("Outward journey cannot be in the past.")}'>
                    <ngbd-datepicker-i18n>
                        <div class="flex-column-container combo-box-left">
                            <label class="header-label date-label" id="outwardjourney" i18n="@@pnlSearchOutwardJourney" for="lounge-date"
                                >Outward Date (DD/MM/YYYY)</label
                            >
                            <i
                                class="ico-calendar combo-box-icon" aria-hidden="true"
                                style="cursor: pointer"
                            ></i>
                            <app-datepicker
                                [minDate]='searchParameters.min_date'
                                [startDate]='searchParameters.date'
                                [model]="searchParameters.date"
                                [class]="'combo-box'"
                                [componentId]="'lounge-date'"
                                (newDate)="searchParameters.date = $event"
                            >
                            </app-datepicker>
                        </div>
                        <div class="flex-column-container select-box-right">
                            <label
                                id="pnl-search-outward-time"
                                class="header-label"
                                i18n="@@PNLSearchOutwardTime"
                                for="pnl_outward_time"
                                >Outward Time</label
                            >
                            <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                            <select
                                class="select-box"
                                [(ngModel)]="searchParameters.time"
                                aria-labelledby="pnl-search-outward-time"
                                id="pnl_outward_time">
                                <option *ngFor="let time of timesArray" [ngValue]="time">{{ time }}</option>
                            </select>
                        </div>
                </ngbd-datepicker-i18n>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<app-lounge-loading-screen></app-lounge-loading-screen>
