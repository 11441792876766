<!-- Air Parking -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <div [class.duplicate-item-wrapper]="duplicateBooking">
      <div *ngIf="duplicateBooking" class="duplicate-item-date">
        <i class="ico-parking ico-material"></i>&nbsp;
        <ng-container *ngIf="parking?.detail?.arrivalDatetime as arrivalDatetime">
          <span i18n="@@basketPanelParkingArrival">Check-in:</span>
          <strong>{{ arrivalDatetime | sabsDate: 5}}</strong>
        </ng-container>
        <ng-container *ngIf="parking?.detail?.departDatetime as departDatetime">
          <span i18n="@@basketPanelParkingDeparture">Check-Out:</span>
          <strong>{{ departDatetime | sabsDate: 5}}</strong>
        </ng-container>     
      </div>

      <div [class.duplicate-item-info]="duplicateBooking">
        <div class="cart_item_heading">
          <ng-container *ngIf="!duplicateBooking">
            <i class="ico-parking ico-material"></i>&nbsp;
          </ng-container>
          <span>{{ parking.detail.name }}</span>
          <button *ngIf="!duplicateBooking"
                  class="cart_item_remove ico-trash"
                  ngbTooltip="Remove {{parking.detail.name}} from your {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                  attr.aria-label="Remove {{parking.detail.name}} parking from your {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                  i18n-ngbTooltip
                  (click)="remove.emit()"
                  id="remove-basketitem-{{parking.id}}"></button>
          <img *ngIf="!duplicateBooking"
               src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{ displayItinerary ?  'luggage_icon' : 'basket_move'}}.svg"
               class="move_basket_icon"
               role="button"
               ngbTooltip="Move to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
               i18n-ngbTooltip
               attr.aria-label="Move {{parking.detail.name}} parking to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
               i18n-aria-label
               (click)="openChangeModal.emit()"
               (keydown.enter)="openChangeModal.emit()"
                 (keydown.space)="openChangeModal.emit()"
                 tabindex="0"
                 id="share-basketitem-{{parking.id}}">
        </div>
        <div class="cart_item_destination"
             id="parking-{{displayItinerary ? 'itinerary' : 'basket'}}-dest-{{itemIndex}}"
             *ngIf='parking.searchQuery.locationCode'>
            {{ parking.searchQuery.airportFullName 
              ? parking.searchQuery.airportFullName + ' (' + parking.searchQuery.locationCode + ')'
              : parking.searchQuery.locationCode | airportIata | async 
            }}
          ({{ getTerminal(parking.searchQuery.terminal ) | translate }})
        </div>
      </div>
    </div>
    <ng-container *ngIf="!duplicateBooking">
      <div *ngIf="parking?.detail?.arrivalDatetime as arrivalDatetime">
        <span i18n="@@basketPanelParkingArrival">Check-in:</span>
        <strong>{{ arrivalDatetime | sabsDate : 5 }}</strong>
      </div>
      <div *ngIf="parking?.detail?.departDatetime as departDatetime"
           class="departure">
        <span i18n="@@basketPanelParkingDeparture">Check-Out:</span>
        <strong>{{ departDatetime | sabsDate : 5 }}</strong>
      </div>
      <div class="car_cart_item_to_point"></div>
    </ng-container>
    <div class="cart_item_price">
      <strong *ngIf="duplicateBooking" i18n="@@basketPanelParkingCost">Cost</strong>
      <strong>{{ parking.price | currency: parking.currency }}</strong>
      <em *ngIf="!duplicateBooking" i18n="@@basketPanelParkingTotalPrice">Total Price</em>
    </div>
  </div>
</div>
