<ng-container *ngIf="name">
  <label
    class="header-label"
    for="clonedBasketItemName"
    i18n="@@parkingdetails_name"
    >Name:</label>
  <input
    class="input-box input-box--no-icon"
    i18n-placeholder
    [placeholder]="name"
    [disabled]="true"
    id="clonedBasketItemName" />
</ng-container>
<ng-container *ngIf="flightNumbers">
  <label
    class="header-label"
    for="clonedBasketFlightNumber"
    i18n="@@filterFlightsFlightNumber"
    >Flight Number</label>
  <input
    class="input-box input-box--no-icon"
    i18n-placeholder
    [placeholder]="flightNumbers"
    [disabled]="true"
    id="clonedBasketFlightNumber" />
</ng-container>