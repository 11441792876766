import { Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import {
  FlightSortTypes,
  FlightEnterpriseSearchInterface,
  FlightSearchType,
  CombinedFlight,
  FlightItineraryWithExtensions,
  FlightTempParams,
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  ServiceType,
  HelperRoutines,
  WithSubscriptionComponent,
  FlightJourney
} from '@sabstravtech/obtservices/angular';
import { DeviceDetector } from '../../../../vendor/services/device-detector.service';
import moment from 'moment';
@Component({
  selector: 'app-flight-tickets',
  templateUrl: './flight-tickets.component.html',
  styleUrls: ['./flight-tickets.component.scss']
})
export class FlightTicketsComponent extends WithSubscriptionComponent implements OnInit, OnChanges, OnDestroy {
  private _flights: FlightItineraryWithExtensions[] | FlightItineraryWithExtensions[][];

  @Input()
  set flights(value: FlightItineraryWithExtensions[] | FlightItineraryWithExtensions[][]) {
    // tslint:disable-next-line:max-line-length
    this._flights = value;
  }
  get flights() {
    return this._flights;
  }
  @Output() selectExchangeFlight: EventEmitter<FlightItineraryWithExtensions> = new EventEmitter();

  combinedFlights: {} = {};
  multiCityByScheduleFlights: {} = {};
  get combinedFlightsKeys(): string[] {
    return !this.searchParams.bySchedule ? Object.keys(this.combinedFlights) : Object.keys(this.multiCityByScheduleCombinedFlightsList);
  }
  @Input() isDual = false;
  @Input() isDualReturn = false;
  public selectedSegmentsId: number[] = [];
  public selectedItemsId: string[] = [];
  @Input() isExchange = false;
  @Input() isPricingExchange = false;
  flightSortTypes = FlightSortTypes;
  typesArray = Object.values(this.flightSortTypes);
  currentFlightSlice: string[]; // This will be the current 10 results of the page
  noOfPages = 0;
  currentPage = 1;
  pageSize = 10;
  flightOffset = 1;
  flightBoundary = 10;
  subscription: Subscription;
  isMultipleBooking = false;
  searchParams: FlightEnterpriseSearchInterface;
  FlightSearchType = FlightSearchType;
  search: FlightTempParams;
  // Enterprise Variables
  ServiceType: typeof ServiceType = ServiceType;
  multiCityByScheduleFlightsList: FlightJourney[] = [];
  multiCityByScheduleCombinedFlightsList: {} = {};
  source: string;
  requiresReason: string[] = [];
  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    public dialog: MatDialog,
    private helpers: HelperRoutines
  ) {
    super();
    this.searchParams = this.searchService.searches[ServiceType.Flight];
  }

  ngOnInit() {
    this.search = this.searchService.searches[ServiceType.Flight].originalUserSearch;
    // this.currentFlightSlice = this.flights; // ! Testing comment out when done
    this.initialPageSetup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flights) {
      if (this.searchParams.bySchedule) {
        this.seachFlightsBySchedule();
      } else {
        this.flights = changes.flights.currentValue;
        this.combinedFlights = {};
        this.flights.forEach(flight => {
          let compId = '';
          const flights = flight.inboundFlights
            ? flight.inboundFlights.concat(flight.outboundFlights)
            : flight.outboundFlights;

          flights.forEach((journey, i) => {
            if (journey) {
              if (i !== 0) {
                compId += '|';
              }
              compId += `${journey.marketingFlightNumber},${journey.dateTimeDeparting},${journey.originAirport}`;
            }
          });
          if (!this.combinedFlights[compId]) {
            this.combinedFlights[compId] = { flights: [flight], flightId: compId } as CombinedFlight;
          } else if (
            !this.combinedFlights[compId].flights.find(
              flightId => flightId.total.price === flight.total.price
            )
          ) {
            (this.combinedFlights[compId] as CombinedFlight).flights.push(flight);
          }
        });

        // this.combinedFlightsKeys.forEach(key => {
        //   (this.combinedFlights[key] as CombinedFlight).flights = (
        //     this.combinedFlights[key] as CombinedFlight
        //   ).flights.sort((a, b) => a.total.price - b.total.price);
        // });
        // console.log(this.combinedFlights);
      }
      this.currentPage = 1;
    }

    this.initialPageSetup();
  }
  ngOnDestroy() { }

  seachFlightsBySchedule() {
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    if (this.flights) {
      // console.log('in segment', this.flights[this.searchParams.selectedSegment]);
      this.filterFlightsBySchedule(this.flights[this.searchParams.selectedSegment]);
    }
  }

  filterFlightsBySchedule(multiCityFlights) {
    this.multiCityByScheduleCombinedFlightsList = {};
    if (multiCityFlights) {
      const marketingCarrier = this.multiCityByScheduleFlightsList[0]?.marketingCarrier;
      const operatingCarrier = this.multiCityByScheduleFlightsList[0]?.operatingCarrier;
      const governingCarrier = this.multiCityByScheduleFlightsList[0]?.governingCarrier;
      // Filter flights based on marketingCarrier if it exists
      if (marketingCarrier && operatingCarrier) {
        multiCityFlights = this.filterFlightsByCarrier(multiCityFlights, marketingCarrier, operatingCarrier, governingCarrier);
      }
      multiCityFlights.forEach(flight => {
        let compId = '';
        const flights = flight.outboundFlights;

        flights.forEach((journey, i) => {
          if (journey) {
            if (i !== 0) {
              compId += '|';
            }
            compId += `${journey.marketingFlightNumber},${journey.dateTimeDeparting},${journey.originAirport}`;
          }
        });
        if (!this.multiCityByScheduleCombinedFlightsList[compId]) {
          this.multiCityByScheduleCombinedFlightsList[compId] = { flights: [flight], flightId: compId } as CombinedFlight;
        } else if (this.multiCityByScheduleCombinedFlightsList[compId].flights) {
          // Get unique cabin classes for the current flight
          const classes = flight.outboundFlights.map(item => item.cabinClass).sort().join(',');
          // Check if any of the existing flights have the same cabin classes
          const isDuplicate = this.multiCityByScheduleCombinedFlightsList[compId].flights.some(existingFlight =>
            existingFlight.outboundFlights.map(item => item.cabinClass).sort().join(',') === classes
          );
          if (!isDuplicate) {
            // Add flight if it's not a duplicate
            (this.multiCityByScheduleCombinedFlightsList[compId] as CombinedFlight).flights.push(flight);
          }
        }
      });
    }

    // console.log(this.multiCityByScheduleCombinedFlightsList);

    this.initialPageSetup();
  }

  filterFlightsByCarrier(multiCityFlights: FlightItineraryWithExtensions[], marketingCarrier: string, operatingCarrier: string, governingCarrier: string) {
    return multiCityFlights.filter((flight: FlightItineraryWithExtensions) =>
      flight.outboundFlights.every(outbound => outbound.marketingCarrier === marketingCarrier || outbound.operatingCarrier === operatingCarrier || outbound.governingCarrier === governingCarrier)
    );
  }

  initialPageSetup(page = 1) {
    this.currentFlightSlice = [];

    // now we need to calulate the number of pages - ensure to round up
    const pageCalc = Math.ceil(this.flights.length / this.pageSize);
    // console.log('+++ Calculated Pages: ', pageCalc, ' +++');
    this.noOfPages = pageCalc;

    // now assuming we have at least one page - take the first piece of the action
    if (this.noOfPages > 0) {
      this.currentFlightSlice = this.getPageOfFlights(page);
    }
  }

  /**
   * @description - get a page of flights - given the page number (NOT 0 INDEXED)
   * @param pageNo - the number of the page
   * @returns - an array of Flight Discriptors - the page of flights
   */
  getPageOfFlights(pageNo: number): string[] {
    let slice: string[] = [];

    // we need to calculate the offset - should be the pageNo -1 * the pageSize
    const offset: number = (pageNo - 1) * this.pageSize;
    // console.log('+++ Calculated Offset for page: ', pageNo, ' | ', offset, ' +++');

    if (offset >= 0) {
      this.flightOffset = offset + 1;
      this.flightBoundary = offset + this.pageSize;
      if (this.flightBoundary > this.combinedFlightsKeys.length) {
        this.flightBoundary = this.combinedFlightsKeys.length;
      }
      slice = this.combinedFlightsKeys.slice(offset, offset + this.pageSize);
    } else {
      // we really shouldn't be at this point - we've somehow got an out of cheese error - please restart the universe
      console.error('+++ Unable to get page of results - calculated offset was less than 0  +++');
    }

    // console.log('+++ Flights Slice: ', slice, ' +++');
    return slice;
  }

  /**
   * @description - change the page - called from the pagination element
   * @param pageNo - the page to go to
   */
  changePage(pageNo: number) {
    // this.currentPage = pageNo;
    this.currentFlightSlice = this.getPageOfFlights(pageNo);
  }

  selectedMultiCityFlight(flight: FlightItineraryWithExtensions) {
    this.multiCityByScheduleFlightsList = [...this.multiCityByScheduleFlightsList, ...flight.outboundFlights];
    this.source = flight.source;
    this.requiresReason = !this.requiresReason ? flight.requiresReasonKeys : this.requiresReason;

    this.selectedSegmentsId.push(this.searchParams.selectedSegment);
    this.selectedItemsId.push(flight.scheduleId);

    if (this.searchParams.selectedSegment + 1 < this.search?.multiDestination.length) {
      this.searchParams.selectedSegment++;
    }
  }

  deselectMultiCityFlight(flight: CombinedFlight) {
    flight?.flights.some((item: FlightItineraryWithExtensions) => item.outboundFlights.forEach(outboundFlight => {
      this.multiCityByScheduleFlightsList = this.multiCityByScheduleFlightsList.filter(item => item.id !== outboundFlight.id);
    }));

    this.selectedSegmentsId = this.selectedSegmentsId.filter(item => item !== this.searchParams.selectedSegment);
    flight?.flights.forEach((flight: FlightItineraryWithExtensions) => this.selectedItemsId = this.selectedItemsId.filter(item => item !== flight.scheduleId));
  }

  removeItems(isReturn) {
    if (isReturn) {
      this.multiCityByScheduleFlightsList = [];
      this.selectedSegmentsId = [];
      this.selectedItemsId = [];
      this.searchParams.selectedSegment = 0;
    }
  }

  checkAviability(flight: CombinedFlight): boolean {
    return flight?.flights.some((item: FlightItineraryWithExtensions) => this.selectedItemsId.includes(item.scheduleId));
  }

  disable(): boolean {
    return this.selectedSegmentsId.includes(this.searchParams.selectedSegment);
  }

}