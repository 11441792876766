<!-- Rail -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <ng-container *ngIf="rail.searchQuery.journeyType === IrlJourneyType.SingleJourney">
      <ng-container *ngIf="rail?.detail as detail">
        <!-- <div *ngIf="!detail.outbound?.ticketExtras"> -->
        <div [class.duplicate-item-wrapper]="duplicateBooking">
          <div *ngIf="duplicateBooking" class="duplicate-item-date">
            <i class="ico-rail"></i>&nbsp;
            <ng-container *ngIf="detail?.outwardDetail?.departureDateTime as departureDateTime">
              <span i18n="@@basketPanelDepartureDateRail">Date:</span>
              <strong>{{ departureDateTime | sabsDate : 1 }}</strong>
            </ng-container>
          </div>
          <div [class.duplicate-item-info]="duplicateBooking">
            <div class="cart_item_heading">
              <ng-container *ngIf="!duplicateBooking">
                <i class="ico-rail"></i>&nbsp;
              </ng-container>
              <span
                >{{ detail.outwardDetail.departureStationName }} to
                {{ detail.outwardDetail.arrivalStationName }}</span
              >
              <button
                *ngIf="!duplicateBooking"
                class="cart_item_remove ico-trash"
                ngbTooltip="Remove {{ detail.outwardDetail.departureStationName }} to {{
                  detail.outwardDetail.arrivalStationName
                }} from your {{ displayItinerary ? 'itinerary' : 'basket' }}"
                attr.aria-label="Remove {{ detail.outwardDetail.departureStationName }} to {{
                  detail.outwardDetail.arrivalStationName
                }} rail from your {{ displayItinerary ? 'itinerary' : 'basket' }}"
                i18n-ngbTooltip
                (click)="remove.emit()"
                id="remove-basketitem-{{ rail.id }}-0"></button>
              <img
                *ngIf="!duplicateBooking"
                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{
                  displayItinerary ? 'luggage_icon' : 'basket_move'
                }}.svg"
                ngbTooltip="Move to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                i18n-ngbTooltip
                class="move_basket_icon"
                role="button"
                attr.aria-label="Move {{ detail.outwardDetail.departureStationName }} to {{
                  detail.outwardDetail.arrivalStationName
                }} rail to different {{ displayItinerary ? 'itinerary' : 'basket' }}"
                (click)="openChangeModal.emit()"
                (keydown.enter)="openChangeModal.emit()"
                (keydown.space)="openChangeModal.emit()"
                tabindex="0"
                id="share-basketitem-{{ rail.id }}-0" />
            </div>
            <ng-container *ngIf="detail?.outwardDetail?.supplierTickets[0] as supplierTicket">
              <div class="cart_item_operator" *ngIf="supplierTicket?.legs as legs">
                <span i18n="@@basketPanelOperatorRail">Operator:</span>
                <strong>{{ legs[0].operator }}</strong>
              </div>
              <div
                class="cart_item_rail_ticket_name"
                *ngIf="supplierTicket?.ticketDescription as ticketDescription">
                <strong>{{ ticketDescription }}</strong>
              </div>
            </ng-container>
            <div
              *ngIf="
                !duplicateBooking && detail?.outwardDetail?.departureDateTime as departureDateTime
              "
              class="cart_item_rail_ticket_times">
              <ng-container *ngIf="!duplicateBooking">
                <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                <strong>{{ departureDateTime | standardDate }}</strong>
              </ng-container>
              <span *ngIf="duplicateBooking">{{ departureDateTime | date : 'HH:mm' }}</span>
            </div>
            <div class="car_cart_item_to_point"></div>
            <div class="cart_item_price">
              <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostRail">Cost</strong>
              <strong>{{ rail.price | currency : rail.currency }}</strong>
              <em *ngIf="!duplicateBooking">
                <span i18n="@@basketPanelTotalPriceRail">&nbsp;Total Price</span>
                <span *ngIf="rail?.adults > 1">(For {{ rail.adults }} travellers)</span>
              </em>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="rail.searchQuery.journeyType === 'ReturnJourney'">
      <ng-container *ngIf="rail?.detail as detail">
        <div>
          <div [class.duplicate-item-wrapper]="duplicateBooking">
            <div *ngIf="duplicateBooking" class="duplicate-item-date">
              <i class="ico-rail"></i>&nbsp;
              <ng-container *ngIf="detail?.outwardDetail?.departureDateTime as departureDateTime">
                <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                <strong>{{ departureDateTime | sabsDate : 1 }}</strong>
              </ng-container>
            </div>
            <div [class.duplicate-item-info]="duplicateBooking">
              <div class="cart_item_heading" *ngIf="detail.outwardDetail as outwardDetail">
                <ng-container *ngIf="!duplicateBooking">
                  <i class="ico-rail"></i>&nbsp;
                </ng-container>
                <span
                  >{{ outwardDetail.departureStationName }} to
                  {{ outwardDetail.arrivalStationName }}</span
                >
                <button
                  *ngIf="!duplicateBooking"
                  class="cart_item_remove ico-trash"
                  i18n-title
                  attr.title="Remove {{ outwardDetail.departureStationName }} to {{
                    outwardDetail.arrivalStationName
                  }} rail from your {{ displayItinerary ? 'itinerary' : 'basket' }}"
                  attr.aria-label="Move {{ outwardDetail.departureStationName }} to {{
                    outwardDetail.arrivalStationName
                  }} rail to different {{ displayItinerary ? 'itinerary' : 'basket' }}"
                  (click)="remove.emit()"
                  id="remove-basketitem-{{ rail.id }}-1"></button>
                <img
                  *ngIf="!duplicateBooking"
                  src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/basket_move.svg"
                  class="move_basket_icon"
                  ngbTooltip="Move to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                  i18n-ngbTooltip
                  role="button"
                  aria-label="Move to different {{ displayItinerary ? 'itinerary' : 'basket' }}"
                  ngbTooltip="Move item to different {{
                    displayItinerary ? 'itinerary' : 'basket'
                  }}"
                  (click)="openChangeModal.emit()"
                  (keydown.enter)="openChangeModal.emit()"
                  (keydown.space)="openChangeModal.emit()"
                  tabindex="0"
                  id="share-basketitem-{{rail.id}-1}" />
              </div>
              <ng-container *ngIf="detail?.outwardDetail?.supplierTickets[0] as supplierTicket">
                <div class="cart_item_operator" *ngIf="supplierTicket?.legs as legs">
                  <span i18n="@@basketPanelOperatorRail">Operator:</span>
                  <strong>{{ legs[0].operator }}</strong>
                </div>
                <div
                  class="cart_item_rail_ticket_name"
                  *ngIf="supplierTicket?.ticketDescription as ticketDescription">
                  <strong>{{ ticketDescription }}</strong>
                </div>
              </ng-container>
              <div
                *ngIf="detail?.outwardDetail?.departureDateTime as departureDateTime"
                class="cart_item_rail_ticket_times">
                <ng-container *ngIf="!duplicateBooking">
                  <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                  <strong>{{ departureDateTime | standardDate }}</strong>
                </ng-container>
                <span *ngIf="duplicateBooking">{{ departureDateTime | date : 'HH:mm' }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="!duplicateBooking" class="rail_return_container"></div>
          <div [class.duplicate-item-wrapper]="duplicateBooking">
            <div *ngIf="duplicateBooking && detail.inwardDetail" class="duplicate-item-date">
              <i class="ico-rail"></i>&nbsp;
              <ng-container *ngIf="detail?.inwardDetail?.departureDateTime as departureDateTime">
                <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                <strong>{{ departureDateTime | sabsDate : 1 }}</strong>
              </ng-container>
            </div>
            <div [class.duplicate-item-info]="duplicateBooking">
              <div class="cart_item_heading" *ngIf="detail.inwardDetail as inwardDetail">
                <ng-container *ngIf="!duplicateBooking">
                  <i class="ico-rail"></i>&nbsp;
                </ng-container>
                <span
                  >{{ inwardDetail.departureStationName }} to
                  {{ inwardDetail.arrivalStationName }}</span
                >
              </div>
              <ng-container *ngIf="detail?.inwardDetail?.supplierTickets[0] as supplierTicket">
                <div class="cart_item_operator" *ngIf="supplierTicket?.legs as legs">
                  <span i18n="@@basketPanelOperatorRail">Operator:</span>
                  <strong>{{ legs[0].operator }}</strong>
                </div>
                <div
                  class="cart_item_rail_ticket_name"
                  *ngIf="supplierTicket?.ticketDescription as ticketDescription">
                  <strong>{{ ticketDescription }}</strong>
                </div>
              </ng-container>
              <div
                *ngIf="detail?.inwardDetail?.departureDateTime as departureDateTime"
                class="cart_item_rail_ticket_times">
                <ng-container *ngIf="!duplicateBooking">
                  <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                  <strong>{{ departureDateTime | standardDate }}</strong>
                </ng-container>
                <span *ngIf="duplicateBooking">{{ departureDateTime | date : 'HH:mm' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="car_cart_item_to_point"></div>
        <div class="cart_item_price">
          <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostRail">Cost</strong>
          <strong>{{ rail.price | currency : rail.currency }}</strong>
          <em *ngIf="!duplicateBooking">
            <span i18n="@@basketPanelTotalPriceRail">&nbsp;Total Price</span>
            <span *ngIf="rail?.adults > 1">(For {{ rail.adults }} travellers)</span>
          </em>
        </div>
      </ng-container>
    </ng-container>
    <!-- TODO: handle OpenReturnJourney case -->
    <!-- TODO: handle DualSingleJourney case -->
  </div>
  <app-irl-journey-details *ngIf="!duplicateBooking" [basketItem]="rail"></app-irl-journey-details>
</div>
