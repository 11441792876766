<!-- Lounges -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
    <div class="cart_item_wrapper">
        <div [class.duplicate-item-wrapper]="duplicateBooking">
          <div *ngIf="duplicateBooking" class="duplicate-item-date">
            <i class="ico-lounge ico-material"></i>&nbsp;
              <span i18n="@@basketPanelCheckInLounges">Check-In:</span>
              <strong>{{ lounge.detail.arrivalDatetime | sabsDate: 5 }}</strong>
          </div>
          <div [class.duplicate-item-info]="duplicateBooking">
            <div class="cart_item_heading">
              <ng-container *ngIf="!duplicateBooking">
                <i  class="ico-lounge ico-material"></i>&nbsp;
              </ng-container>
              <span>{{ lounge.detail.name }}</span>
              <button *ngIf="!duplicateBooking"
                      class="cart_item_remove ico-trash"
                      ngbTooltip="Remove  {{lounge.detail.name}} from your {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                      attr.aria-label="Remove  {{lounge.detail.name}} lounge from your {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                      i18n-ngbTooltip
                      i18n-aria-label
                      (click)="remove.emit()"
                      id="remove-basketitem-{{lounge.id}}"></button>
              <img *ngIf="!duplicateBooking"
                   src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{ displayItinerary ?  'luggage_icon' : 'basket_move'}}.svg"
                   ngbTooltip="Move to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                   i18n-ngbTooltip
                   class="move_basket_icon"
                   role="button"
                   attr.aria-label="Move {{lounge.detail.name}} lounge to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                   i18n-aria-label
                   (click)="openChangeModal.emit()"
                   (keydown.enter)="openChangeModal.emit()"
               (keydown.space)="openChangeModal.emit()"
               tabindex="0"
               id="share-basketitem-{{lounge.id}}">
          </div>
          <div class="cart_item_destination"
               *ngIf='lounge.detail.destination'
               id="lounge-{{displayItinerary ? 'itinerary' : 'basket'}}-dest-{{itemIndex}}">
              {{ lounge.detail.destination }} ({{ lounge.detail.airportIata
              }})
              ({{ getTerminal(lounge.searchQuery.terminal ) | translate}})
          </div>
        </div>
        </div>


        <div *ngIf="!duplicateBooking" class="check_in">
            <span i18n="@@basketPanelCheckInLounges">Check-In:</span>
            <strong>{{ lounge.detail.arrivalDatetime | sabsDate : 5 }}</strong>
        </div>
        <div class="car_cart_item_to_point"></div>
        <div class="cart_item_price">
            <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostLounges">Cost</strong>
            <strong>{{ lounge.price | currency: lounge.currency}}</strong>
            <em *ngIf="!duplicateBooking" i18n="@@basketPanelTotalPriceLounges">Total Price</em>
        </div>
    </div>
</div>
