<div class="modal-header">
  <h3 class="modal-title" i18n="@@addOn">Add-on</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<p class="addon_info" i18n="@@travelCardInfo">
  Unlimited journey on all London Underground, Buses, Trams, Docklands Light Rail, Over-ground
  Trains and National Rail Services within zones of the selected travelcard. The costs shown in the
  grid are for an individual traveller. Please note that the Peak travel period is before 09.30 on
  weekdays
</p>
<b class="travelcard_header" i18n="@@travelCardSelection">Travelcard Selection</b>
<div class="modal-body flex-row-container flex-column-mobile">
  <div class="travel-card-item">
    <div class="flex-column-container travel-card-box" *ngIf="noOfCards >= 1">
      <p i18n="@@travelCardDate">Date of Travel:</p>
      <ngbd-datepicker-i18n class="date">
        <i
          class="ico-calendar combo-box-icon cursor_pointer"
          aria-hidden="true"
          (click)="d1.toggle()"></i>
        <input
          autocomplete="off"
          placeholder="DD/MM/YYYY"
          class="combo-box"
          [minDate]="startDate"
          [startDate]="startDate"
          [(ngModel)]="bookingDate[0]"
          [navigation]="'arrows'"
          (click)="d1.toggle(); updateState()"
          ngbDatepicker
          #d1="ngbDatepicker"
          name="dp1"
          id="dp1"
          [markDisabled]="isDisabled"
          [container]="'body'"
          aria-labelledby="rail-outbound-date" />
        <!-- <i class="ico-clock combo-box-icon combo-box-icon--right"></i> -->
        <span id="rail-outbound-date" hidden i18n="AriaRailOutboundDate">Outbound date</span>
      </ngbd-datepicker-i18n>
      <div class="flex-row-container">
        <p class="london_zones london_zones_1" i18n="@@travelCardZone4">London Zone 1-4</p>
        <p class="london_zones london_zones_2" i18n="@@travelCardZone6">London Zone 1-6</p>
      </div>
      <div class="flex-row-container">
        <mat-button-toggle-group
          multiple
          (change)="toggleChange($event, 0)"
          class="toggle_group"
          name="card-0">
          <ng-container
            *ngFor="let extra of outboundRailJourneyOption?.ticketExtras; let index = index">
            <div class="flex-column-container toggle_container">
              <p>{{ extra?.description?.includes('Peak') ? 'Off-Peak' : 'Peak' }}</p>
              <mat-button-toggle
                class="peak_button"
                *ngIf="isAdult"
                value="{{ extra?.adultAmount }}-{{ index }}"
                (click)="updateTravelCard(0, extra)"
                >{{ extra?.adultAmount | currency : extra?.currency : 'symbol' }}</mat-button-toggle
              >
              <mat-button-toggle
                class="peak_button"
                *ngIf="isChild"
                value="{{ extra?.childAmount }}-{{ index }}"
                (click)="updateTravelCard(0, extra)"
                >{{ extra?.childAmount | currency : extra?.currency : 'symbol' }}</mat-button-toggle
              >
            </div>
          </ng-container>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div class="travel-card-item" *ngIf="noOfCards >= 2">
    <div class="flex-column-container travel-card-box">
      <p i18n="@@travelCardDate">Date of Travel:</p>
      <ngbd-datepicker-i18n class="date">
        <i
          class="ico-calendar combo-box-icon cursor_pointer"
          (click)="d2.toggle()"
          aria-hidden="true"></i>
        <input
          autocomplete="off"
          placeholder="DD/MM/YYYY"
          class="combo-box"
          [minDate]="startDate"
          [startDate]="startDate"
          [(ngModel)]="bookingDate[1]"
          [navigation]="'arrows'"
          (click)="d2.toggle(); updateState()"
          ngbDatepicker
          #d2="ngbDatepicker"
          name="dp2"
          [markDisabled]="isDisabled"
          id="dp2"
          [container]="'body'"
          aria-labelledby="rail-outbound-date" />
        <!-- <i class="ico-clock combo-box-icon combo-box-icon--right"></i> -->
        <span id="rail-outbound-date" hidden i18n="AriaRailOutboundDate">Outbound date</span>
      </ngbd-datepicker-i18n>
      <div class="flex-row-container">
        <p class="london_zones london_zones_1" i18n="@@travelCardZone4">London Zone 1-4</p>
        <p class="london_zones london_zones_2" i18n="@@travelCardZone6">London Zone 1-6</p>
      </div>
      <div class="flex-row-container">
        <mat-button-toggle-group
          [disabled]="!bookingDate[1]"
          multiple
          (change)="toggleChange($event, 1)"
          class="toggle_group"
          name="card-1">
          <ng-container
            *ngFor="let extra of outboundRailJourneyOption?.ticketExtras; let index = index">
            <div class="flex-column-container toggle_container">
              <p>{{ extra?.description?.includes('Peak') ? 'Off-Peak' : 'Peak' }}</p>
              <mat-button-toggle
                [disabled]="!bookingDate[1]"
                *ngIf="isAdult"
                class="peak_button"
                value="{{ extra?.adultAmount }}-{{ index }}"
                (click)="bookingDate[1] ? updateTravelCard(1, extra) : null"
                >{{ extra?.adultAmount | currency : extra?.currency : 'symbol' }}</mat-button-toggle
              >
              <mat-button-toggle
                [disabled]="!bookingDate[1]"
                *ngIf="isChild"
                value="{{ extra?.childAmount }}-{{ index }}"
                class="peak_button"
                (click)="bookingDate[1] ? updateTravelCard(1, extra) : null"
                >{{ extra?.childAmount | currency : extra?.currency : 'symbol' }}</mat-button-toggle
              >
            </div>
          </ng-container>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div class="travel-card-item" *ngIf="noOfCards >= 3">
    <div class="flex-column-container travel-card-box">
      <p i18n="@@travelCardDate">Date of Travel:</p>
      <ngbd-datepicker-i18n class="date">
        <i
          class="ico-calendar combo-box-icon cursor_pointer"
          (click)="d3.toggle()"
          aria-hidden="true"></i>
        <input
          autocomplete="off"
          placeholder="DD/MM/YYYY"
          class="combo-box"
          [minDate]="startDate"
          [startDate]="startDate"
          [(ngModel)]="bookingDate[2]"
          [navigation]="'arrows'"
          (click)="d3.toggle(); updateState()"
          ngbDatepicker
          [markDisabled]="isDisabled"
          #d3="ngbDatepicker"
          [container]="'body'"
          name="dp3"
          id="dp3"
          aria-labelledby="rail-outbound-date" />
        <!-- <i class="ico-clock combo-box-icon combo-box-icon--right"></i> -->
        <span id="rail-outbound-date" hidden i18n="AriaRailOutboundDate">Outbound date</span>
      </ngbd-datepicker-i18n>
      <div class="flex-row-container">
        <p class="london_zones london_zones_1" i18n="@@travelCardZone4">London Zone 1-4</p>
        <p class="london_zones london_zones_2" i18n="@@travelCardZone6">London Zone 1-6</p>
      </div>
      <div class="flex-row-container">
        <mat-button-toggle-group
          [disabled]="!bookingDate[2]"
          multiple
          (change)="toggleChange($event, 2)"
          class="toggle_group"
          name="card-2">
          <ng-container
            *ngFor="let extra of outboundRailJourneyOption?.ticketExtras; let index = index">
            <div class="flex-column-container toggle_container">
              <p>{{ extra?.description?.includes('Peak') ? 'Off-Peak' : 'Peak' }}</p>
              <mat-button-toggle
                [disabled]="!bookingDate[2]"
                *ngIf="isAdult"
                class="peak_button"
                value="{{ extra?.adultAmount }}-{{ index }}"
                (click)="bookingDate[2] ? updateTravelCard(2, extra) : null"
                >{{ extra?.adultAmount | currency : extra?.currency : 'symbol' }}</mat-button-toggle
              >
              <mat-button-toggle
                [disabled]="!bookingDate[2]"
                *ngIf="isChild"
                value="{{ extra?.childAmount }}-{{ index }}"
                class="peak_button"
                (click)="bookingDate[2] ? updateTravelCard(2, extra) : null"
                >{{ extra?.childAmount | currency : extra?.currency : 'symbol' }}</mat-button-toggle
              >
            </div>
          </ng-container>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    title="Cancel Choice"
    (click)="activeModal.close(false)"
    class="small-button small-button--no"
    i18n="@@modalCancel">
    Cancel
  </button>
  <button *ngIf="!isSkip" type="button" class="small-button" (click)="submit()" i18n="@@modalOK">
    Ok
  </button>
  <button *ngIf="isSkip" type="button" class="small-button" (click)="submit()" i18n="@@modalSkip">
    Skip
  </button>
</div>
