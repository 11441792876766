<!-- Fligth new design -->
<ng-container
  *ngIf="{
    flightResultsArray: (flightResults$ | async) || [],
    dualFlightResultsArray: (dualFlightResults$ | async) || []
  } as data">
  <div>
    <h2 class="search_content_titles" i18n="@@flight_searchCriteria">Search Criteria</h2>
    <a
      href="#flight-container-1"
      (click)="skipToResultContent()"
      role="link"
      class="sr-only sr-only-focusable"
      >Skip to Result Content</a
    >
    <app-invoked-user></app-invoked-user>
  </div>
  <div class="flight_container">
    <div class="form_validation" *ngIf="show_errors">
      <button class="error_validation_close" (click)="closeValidationErrors()" aria-label="Close">
        <i class="ico-cancel" aria-hidden="true"></i>
      </button>
      <span class="validation_error" *ngFor="let error of errorsList">{{ error | translate }}</span>
    </div>
    <div class="flight_research_form">
      <app-search-toggle>
        <app-flight-search></app-flight-search>
        <div class="flex-row-container flex-row-container--right flight_research_row">
          <button
            i18n-title
            class="large-button large-button--search"
            title="Start another search"
            (click)="reSearch()"
            i18n="@@flightSearch">
            Search
          </button>
        </div>
      </app-search-toggle>
      <app-risk-alerts [countryCode]="search?.arriveLocation?.countryCode"></app-risk-alerts>
    </div>
    <div
      class="flex-column-container rail_and_carbon"
      *ngIf="searchParams?.railFromFlightSearchPerformed">
      <app-carbon-comparison
        *ngIf="searchParams?.railFromFlightSearchPerformed"
        [carbonPercentage]="carbonPercentage"
        (valueCalculated)="valueCalculated($event)"></app-carbon-comparison>
    </div>

    <div
      class="flex-column-container rail_and_carbon"
      *ngIf="searchParams.eurostarFromFlightSearchPerformed">
      <app-carbon-comparison-eurostar
        *ngIf="searchParams.eurostarFromFlightSearchPerformed"
        (valueCalculated)="valueCalculated($event)"></app-carbon-comparison-eurostar>
    </div>

    <div class="flex-row-container switchrail_container">
      <div
        class="flex-row-container"
        *ngIf="searchParams.railFromFlightSearchPerformed && railResultsCount">
        <button
          id="cheapest-rail-option"
          class="small-button margin-right-5"
          (click)="switchToRail()">
          <i class="ico-rail" aria-hidden="true"></i>
          <span i18n="@@flightRailFrom">Rail From:</span>
          {{ cheapestRail | currency : 'GBP' }}
        </button>
      </div>
      <div
        class="flex-row-container"
        *ngIf="searchService.searches[ServiceType.Hotel]?.cheapestPrice?.amount">
        <button
          (click)="switchToHotel()"
          class="small-button add-hotel-to-search-button"
          id="flightresults-button-switchtoHotel">
          <i class="ico-bed" aria-hidden="true"></i>
          <span i18n="@@flighttHotelsFrom">Hotels From:</span>
          {{
            searchService.searches[ServiceType.Hotel]?.cheapestPrice?.amount
              | currency : searchService.searches[ServiceType.Hotel]?.cheapestPrice?.currencyCode
          }}
        </button>
      </div>
      <div class="flex-row-container rail_and_carbon" *ngIf="isFastTrackEnable || isParkingEnable">
        <div class="flex-row-container">
          <button
            class="small-button margin-right-5"
            id="add-fasttrack-option"
            *ngIf="isFastTrackEnable"
            (click)="applyFastTrackSearch()">
            <i class="ico-fast-track ico-material" aria-hidden="true"></i
            ><span i18n="@@AddFastTrack">Add Fast Track</span>
          </button>
          <button
            class="small-button margin-right-5"
            (click)="applyParkingSearch()"
            *ngIf="isParkingEnable"
            id="add-parking-option">
            <i class="ico-parking ico-material" aria-hidden="true"></i
            ><span i18n="@@AddParking">Add Parking</span>
          </button>
        </div>
      </div>
      <button
        *ngIf="searchParams.eurostarFromFlightSearchPerformed && eurostarResultsCount"
        class="small-button"
        routerLink="/results/eurostar">
        <i class="custom_icon-eurostar"></i><span i18n="@@EurostarFrom">Eurostar From:</span>
        {{ cheapestEurostar | currency : 'GBP' }}
      </button>
      <span
        class="switchrail_text"
        *ngIf="
          searchParams.eurostarFromFlightSearchPerformed && carbonPercentage && eurostarResultsCount
        "
        i18n="@@eurostarSearchRailText"
        >Have you considered the Eurostar option? This could reduce your environmental impact by
        {{ carbonPercentage.toFixed() }}%</span
      >
    </div>

    <div
      class="flights_container"
      id="flight-container-2"
      *ngIf="{ type: searchParams.choosenDisplayType | async } as display">
      <div class="flex-row-container flex-column-mobile flight-header">
        <app-light-toggle-group
          tabindex="-1"
          *ngIf="
            searchParams.originalUserSearch.chosenSearchType !== FlightSearchType.oneWay &&
            search?.chosenSearchType !== FlightSearchType.multiCity
          ">
          <!-- remove getFlightResults() in s65 sprint-->
          <!-- when BY SCHEDULE 
           (click)="
              searchParams.setFlightDisplayType(FlightDisplayTypes.returns); getFlightResults()
            "
          -->
          <app-light-toggle-btn
            [selected]="display.type === FlightDisplayTypes.returns"
            (click)="searchParams.setFlightDisplayType(FlightDisplayTypes.returns)"
            id="flight-toggle-returns"
            iconClass="ico-return"
            tabindex="-1">
            <span i18n="@@flightDisplayReturns">Returns </span> <span>{{ returnsCheapest }}</span>
          </app-light-toggle-btn>
          <app-light-toggle-btn
            [selected]="display.type === FlightDisplayTypes.dualSingles"
            (click)="searchParams.setFlightDisplayType(FlightDisplayTypes.dualSingles)"
            id="flight-toggle-dual-singles"
            iconClass="ico-tickets"
            tabindex="-1">
            <span i18n="@@flilghtDisplayDualsingles">Dual Singles </span>
            <span>{{ dualCheapest }}</span>
          </app-light-toggle-btn>
          <!-- hide bySchedule in s65 sprint-->
          <!-- <app-light-toggle-btn
            *ngIf="data.flightResultsArray?.length"
            [selected]="display.type === FlightDisplayTypes.bySchedule"
            (click)="
              searchParams.setFlightDisplayType(FlightDisplayTypes.bySchedule); getFlightResults()
            "
            tabindex="-1">
            <span i18n="@@muliCityBySchedule"> By Schedule</span>
          </app-light-toggle-btn> -->
        </app-light-toggle-group>
        <div class="flight-title" *ngIf="search?.chosenSearchType === FlightSearchType.return">
          <h2 *ngIf="search?.departLocation && search?.arriveLocation">
            {{ search?.departLocation['destination'] }}
            <span i18n="@@flilghtSearchTo">to</span>
            {{ search?.arriveLocation['destination'] }}
          </h2>
          <div>
            <span i18n="@@OutBoundDate">Outbound Date</span>:
            {{
              search?.outTimeCriteria === 'Anytime'
                ? (search?.outBoundDate | sabsDate : 8)
                : (search?.outBoundDate | sabsDate : 5)
            }}
          </div>
          <div>
            <span i18n="@@ReturnDate">Return Date</span>:
            {{
              search.inTimeCriteria === 'Anytime'
                ? (search?.inboundDate | sabsDate : 8)
                : (search?.inboundDate | sabsDate : 5)
            }}
          </div>
        </div>
        <div class="flight-title" *ngIf="search?.chosenSearchType === FlightSearchType.multiCity">
          <h2 i18n="@@MultiCityTrip">Multi-City Trip</h2>
          <div *ngIf="display.type === FlightDisplayTypes.bySchedule; else byPriceBlock">
            <h2>
              {{ search?.multiDestination[searchParams.selectedSegment].orig.destination }}
              <span i18n="@@flilghtSearchTo">to</span>
              {{ search?.multiDestination[searchParams.selectedSegment].dest.destination }}
            </h2>
            <div>
              <span i18n="@@OutBoundDate">Outbound Date</span>:
              {{
                search?.multiDestination[searchParams.selectedSegment].outTimeCriteria === 'Anytime'
                  ? (search?.multiDestination[searchParams.selectedSegment].date | sabsDate : 8)
                  : (search?.multiDestination[searchParams.selectedSegment].date | sabsDate : 5)
              }}
            </div>
          </div>
          <ng-template #byPriceBlock>
            @for (multiDestination of search?.multiDestination; track multiDestination; let last = $last; let first = $first ) {
              <span class="multi-city-title">
                {{ multiDestination.orig.destination }} ({{multiDestination.orig.gateway}}) - {{ multiDestination.dest.destination }} ({{multiDestination.dest.gateway}}) 
                {{ !last ? ' - ' : ';' }}
              </span>
            }
            <span class="multi-city-title">
              {{search?.multiDestination[0].date| sabsDate : 1}} - {{search?.multiDestination[search?.multiDestination.length - 1].date| sabsDate : 1}}
            </span>
          </ng-template>
        </div>
      </div>
      <app-light-toggle-group
        tabindex="-1"
        *ngIf="search?.chosenSearchType === FlightSearchType.multiCity">
        <app-light-toggle-btn
          [selected]="display.type === FlightDisplayTypes.byPrice"
          (click)="
            searchParams.setFlightDisplayType(FlightDisplayTypes.byPrice); getFlightResults()
          "
          tabindex="-1">
          <span i18n="@@muliCityByPrice">By Price</span> <span>{{ returnsCheapest }}</span>
        </app-light-toggle-btn>
        <app-light-toggle-btn
          [selected]="display.type === FlightDisplayTypes.bySchedule"
          (click)="
            searchParams.setFlightDisplayType(FlightDisplayTypes.bySchedule); getFlightResults()
          "
          tabindex="-1">
          <span i18n="@@muliCityBySchedule"> By Schedule</span>
        </app-light-toggle-btn>
      </app-light-toggle-group>

      <div class="flex-row-container flex-column-mobile">
        <div
          class="app-left-filters background-60 flight-filters-margin-top"
          [ngClass]="{
            'flight-filters-container': search?.chosenSearchType === FlightSearchType.oneWay
          }">
          <div
            class="flex-row-container flex-row-container--right"
            role="button"
            tabindex="0"
            (keydown.enter)="expandedFilters = !expandedFilters"
            (click)="expandedFilters = !expandedFilters"
            attr.aria-label="{{ expandedFilters ? 'Hide Filters' : 'Show Filters' }}"
            attr.aria-expanded="{{ expandedFilters }}">
            <div *ngIf="expandedFilters">
              <span i18n="@@HideFilters">Hide Filters</span>&nbsp;<i
                class="ico-chevron-up"
                aria-hidden="true"></i>
            </div>
            <div *ngIf="!expandedFilters">
              <span i18n="@@ShowFilters">Show Filters</span>&nbsp;<i
                class="ico-chevron-down"
                aria-hidden="true"></i>
            </div>
          </div>
          <app-filter-flights
            [filters]="searchParams.filters | async"
            *ngIf="
              display.type !== FlightDisplayTypes.dualSingles &&
              expandedFilters &&
              !searchParams.bySchedule
            "
            [display]="FlightDisplayTypes.returns"></app-filter-flights>

          <ng-container *ngIf="searchParams.scheduledFilters | async as filters">
            <!-- Pass the filter at index i to app-filter-flights component -->
            <app-filter-flights
              [filters]="filters[searchParams.selectedSegment]"
              *ngIf="
                display.type !== FlightDisplayTypes.dualSingles &&
                expandedFilters &&
                searchParams.bySchedule
              "
              [display]="FlightDisplayTypes.returns"></app-filter-flights>
          </ng-container>

          <app-filter-flights
            [filters]="searchParams.duralSingleFilter | async"
            *ngIf="display.type === FlightDisplayTypes.dualSingles && expandedFilters"
            [display]="FlightDisplayTypes.dualSingles"></app-filter-flights>
        </div>
        <div
          class="flex-column-container width-100 flight-tickets-container"
          *ngIf="search?.departLocation && search?.arriveLocation">
          <div
            *ngIf="
              display.type === FlightDisplayTypes.returns ||
              searchParams.chosenSearchType === FlightSearchType.oneway ||
              searchParams.chosenSearchType === FlightSearchType.multiCity
            ">
            <div *ngIf="data.flightResultsArray?.length">
              <app-flight-tickets [flights]="data.flightResultsArray"> </app-flight-tickets>
            </div>
            <div role="status">
              <ng-container *ngIf="!data.flightResultsArray?.length">
                <ng-container *ngTemplateOutlet="noResults"></ng-container>
              </ng-container>
            </div>
          </div>

          <!-- Dual Singles -->
          <div *ngIf="display.type === FlightDisplayTypes.dualSingles">
            <div *ngIf="data.dualFlightResultsArray?.length">
              <div *ngIf="data.dualFlightResultsArray[0].results.length">
                <app-flight-tickets
                  tabindex="0"
                  [isDual]="true"
                  [flights]="data.dualFlightResultsArray[0].results">
                </app-flight-tickets>
              </div>
              <div role="status">
                <ng-container *ngIf="!data.dualFlightResultsArray[0].results.length">
                  <ng-container *ngTemplateOutlet="noResults"></ng-container>
                </ng-container>
              </div>
              <div class="flight-title">
                <h2 *ngIf="search?.departLocation && search?.arriveLocation">
                  {{ search.arriveLocation['destination'] }}
                  <span i18n="@@flilghtSearchTo">to</span>
                  {{ search.departLocation['destination'] }}
                </h2>
                <div
                  *ngIf="
                    search?.chosenSearchType !== FlightSearchType.oneWay &&
                    search?.chosenSearchType !== FlightSearchType.multiCity
                  ">
                  <span i18n="@@ReturnDate">Return Date</span>:
                  {{
                    search?.inTimeCriteria === 'Anytime'
                      ? (search?.inboundDate | sabsDate : 8)
                      : (search?.inboundDate | sabsDate : 5)
                  }}
                </div>
              </div>
              <div *ngIf="data.dualFlightResultsArray[1].results.length">
                <div *ngIf="!searchParams.fetchingRules; else loadingBlock">
                  <app-flight-tickets
                    [isDualReturn]="true"
                    [flights]="data.dualFlightResultsArray[1].results">
                  </app-flight-tickets>
                </div>
              </div>
              <div role="status">
                <ng-container *ngIf="!data.dualFlightResultsArray[1].results.length">
                  <ng-container *ngTemplateOutlet="noResults"></ng-container>
                </ng-container>
              </div>
              <ng-template #loadingBlock>
                <h2 class="applyingPolicies" i18n="@@applyingPolicies">
                  Applying Policies... <i class="button_spinner"></i>
                </h2>
              </ng-template>
            </div>
            <div role="status">
              <ng-container *ngIf="!data.dualFlightResultsArray?.length">
                <ng-container *ngTemplateOutlet="noResults"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <ng-template #noResults>
          <div class="no_results_message background-10 no_results_flights">
            <h2 class="no_results_message_heading">
              <i class="ico-noflight-1 no_results_icon" aria-hidden="true"></i>Oh, no!
            </h2>
            <div>
              <p>
                <span i18n="@@flightResultsNoMatch"
                  >We couldn't find any fares that match your criteria. Try</span
                >
                <strong i18n="@@flightResultsNoMatchB">&nbsp;updating your search criteria</strong>.
              </p>
              <p i18n="@@flightResultsContact">
                If you can't find exactly what you're looking for, contact a consultant.
              </p>
            </div>

            <ng-template #ReturnSearchTypeError>
              <p>
                <span i18n="@@ReturnSearchTypeError"
                  >We couldn't find any return fares that match your current search criteria. Please
                  select the Dual Singles tab to check if there are any available fares to display.
                  If no fares have been returned try</span
                >
                <strong i18n="@@flightResultsNoMatchB">&nbsp;updating your search criteria</strong>.
              </p>
              <p i18n="@@ReturnSearchTypeErrorResultsContact">
                If you can't find exactly what you're looking for, please contact your Travel
                Manager or Online Support Team for further assistance.
              </p>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
