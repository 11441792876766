import './vendor/services/light.extenders';
import { CommonModule, Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgbActiveModal, NgbPaginationModule, NgbAccordionModule, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { EventMessenager, UserService, TicketQueueService } from '@sabstravtech/obtservices/angular';
import { A11yModule } from '@angular/cdk/a11y';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
// import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { OverRiddenModule } from './overridden-module/overridden.module';
import { StartupModule } from './startup/startup.module';
import { AppComponent } from './vendor/components/app-component/app.component';
import { CookieComponent } from './vendor/components/cookie-component/cookie.component';
import { ModalModule } from './modal-module/modal.module';
import { ThemeComponent } from './vendor/components/theme/theme.component';
import { ModalOpenerComponent } from './vendor/components/modal-opener/modal-opener.component';
import { LanguageService } from './vendor/services/language.service';
import { DDAService } from './vendor//services/dda.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateCSVLoader } from './vendor/classes/translate-csv-locader';
import { ChangeUserComponent } from './vendor/components/change-user/change-user.component';
import { BecomeUserComponent } from './vendor/components/change-user/become-user/become-user.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
// import { AuthModule, provideAuth0 } from '@auth0/auth0-angular';


export function CSVLoaderFactory(httpClient: HttpClient) {
  return new TranslateCSVLoader(httpClient, 'assets/i18n/translations.csv');
}

const ENTRY_COMPONENTS = [AppComponent, ModalOpenerComponent, ChangeUserComponent, ThemeComponent, BecomeUserComponent, CookieComponent];

const SERVICES = [];
@NgModule({
  imports: [
    A11yModule,
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    GoogleMapsModule,
    ModalModule,
    OverRiddenModule,
    ReactiveFormsModule,
    StartupModule,
    ApolloModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CSVLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    }),
  ],
  declarations: [...ENTRY_COMPONENTS],
  providers: [
    // Components
    CookieService,
    DDAService,
    EventMessenager,
    Location,
    LanguageService,
    NgbActiveModal,
    NgbPaginationModule,
    NgbAccordionModule,
    NgbNav,
    UserService,
    TicketQueueService,
    // provideAuth0({
    //   domain: environment.auth0Domain,
    //   clientId: environment.auth0ClientId,
    //   authorizationParams: {
    //     redirect_uri: (window.location.hostname.includes('localhost')) ? 'http://localhost:1234' : environment.redirectUrlScion
    //   }
    // }),
    ...SERVICES,

  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
