<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" i18n="@@modalErrorOccurredTitle" id="no-cc-modal-header">An error occurred!</h3>
    <button type="button" class="close" attr.aria-label="Close" ngbAutoFocus i18n-aria-label (click)="OK()">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@modalNoCCErrorMessage">There are no saved credit cards in your travel profile, to continue with your
      booking please enter a valid credit card via your profile management tool.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="modal_button_okay" (click)="OK()" i18n="@@modalOK">Ok</button>
  </div>
</div>