<ng-template #rt let-r="result" let-t="term">
  <span>
    {{ r.name }} <span *ngIf="r.countryCode">({{ r.countryCode }})</span></span
  >
</ng-template>
<ng-template #rtp let-r="result" let-t="term">
  <span> {{ r.name }}</span>
</ng-template>
<div id="meetingRoomSearchForm1" class="meetingRoom-search-form">
    <div class="inputs-container">
      <div i18n="@@meetingRoomSearchTravellingOfflineMessage" class="meeting-room-message">
        Please fill out this form as the meeting room you wish to book is not available online. When you submit the request a member of our travel team will be in touch with a quote. Please call our travel team if your request is for travel within 48 hours.
      </div>
      <div class="half-container">
        <div class="half-block">
            <app-search-country
                [(country)]="searchParams.country"
                [countries]="searchService.countries"
                title="Country"
                i18n-title>
            </app-search-country>
        </div>
        <div class="half-block">
          <div class="two-thirds-container">
              <div class="two-third">
                <label class="header-label" i18n="@@meetingRoomSearchLocation" for="meetingRoom-pref-loc"
                  >Location</label
                >
                <i class="ico-marker select-box-icon" aria-hidden="true"></i>
                <div class="flex-row-container">
                  <input
                    autocomplete="off"
                    i18n-placeholder
                    placeholder="Location"
                    type="text"
                    class="input-box"
                    id="meetingRoom-pref-loc"
                    onClick="this.select()"
                    [(ngModel)]="searchParams.location"
                    [ngbTypeahead]="searchLocations"
                    [resultTemplate]="rt"
                    [inputFormatter]="formatter"
                    (focus)="focus$.next($event.target.value)"
                    [editable]="false"
                    aria-labelledby="meetingRoom-city-location-search"
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
                  <span id="meetingRoom-city-location-search" hidden i18n="@@AriameetingRoomCityLocationSearch"
                    >Select a city</span
                  >
                </div>
              </div>
              <div class="one-third">
                <label class="header-label" i18n="@@meetingRoomSearchPostcode" for="meetingRoom-postcode-typeahead"
                  >Postcode</label
                >
                <i class="ico-marker input-box-icon" aria-hidden="true"></i>
                <input
                  autocomplete="off"
                  i18n-placeholder
                  placeholder="Postcode"
                  type="text"
                  class="input-box"
                  id="meetingRoom-postcode-typeahead"
                  [ngbTypeahead]="searchPostCode"
                  [(ngModel)]="searchParams.postcode"
                  [resultTemplate]="rtp"
                  onClick="this.select()"
                  (focus)="focus$.next($event.target.value)"
                  [inputFormatter]="postCodeFormatter"
                  [editable]="false"
                  aria-labelledby="aria-meetingRoom-search-postcode" />
                <span id="aria-meetingRoom-search-postcode" hidden i18n="@@AriameetingRoomSearchPostcode"
                  >Postcode</span
                >
              </div>
          </div>
        </div>
      </div>
      <div class="half-container">
        <div class="half-block">
          <div class="half-container">
            <div class="half-block">
              <label class="header-label date-label" id="datelabel" for="meetingroom-date" i18n="@@meetingRoomSearchDate"
              >Date (DD/MM/YYYY)</label
            >
            <div>
              <ngbd-datepicker-i18n>
                <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                <app-datepicker
                  [minDate]="min_date"
                  [startDate]="searchParams.meetingDate"
                  [model]="searchParams.meetingDate"
                  [class]="'combo-box'"
                  [componentId]="'meetingroom-date'"
                  (newDate)="searchParams.meetingDate = $event">
                </app-datepicker>
              </ngbd-datepicker-i18n>
            </div>
          </div>
          <div class="half-block">
            <label
                class="header-label"
                id="duration"
                for="meetingroom-duration-select"
                i18n="@@meetingRoomSearchDuration"
                >Duration</label
              >
              <select
                [(ngModel)]="searchParams.meetingDuration"
                aria-labelledby="duration"
                id="meetingroom-duration-select"
                class="select-box select-box--no-icon">
                <option [ngValue]="null" i18n="@@notSelected">Not selected</option>
                <option [ngValue]="value" *ngFor="let value of searchParams.meetingRoomDurations">
                  {{value | splitCamelCase | translate}}
                </option>
              </select>
            </div>
          </div>
        </div>
    </div>
    <div class="flex-row-container meeting-room-message">
      <h2 i18n="@@meetingRoomLayoutRequirements"
          class="rem-1-5">Layout Requirements</h2>
    </div>
    <div class="half-container">
      <div class="half-block">
        <div class="half-container">
          <div class="half-block">
            <label
                class="header-label"
                id="delegates"
                for="meetingroom-delegates-input"
                i18n="@@meetingRoomSearchDelegates"
                >Delegates</label
              >
            <input type="number"
              class='input-box input-box--no-icon'
              [(ngModel)]="searchParams.delegates"
              min="0"
              step="1"
              aria-labelledby="delegates"
              id="meetingroom-delegates-input"
              placeholder="Enter number of delegates..."
              i18n-placeholder>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-column-container">
      <label
        class="header-label"
        id="select-room-layout"
        i18n="@@meetingRoomSelectRoomLayout"
        >Select Room Layout</label>
    <mat-radio-group
      class="quin-radio-buttons"
      [(ngModel)]="searchParams.layout"
      aria-labelledby="select-room-layout">
      <mat-radio-button
        class="light-primary"
        id="meetingroom-room-radio-{{type | removeSpaces}}"
        color="primary"
        [value]="type"
        *ngFor="let type of searchParams.meetingRoomLayouts">
        {{ type | translate }}
      </mat-radio-button>
    </mat-radio-group>
    </div>
    <div class="half-container">
      <div class="half-block">
        <div class="half-container">
            <div class="half-block">
              <label
                class="header-label"
                id="equipment"
                for="meetingroom-equipment-select"
                i18n="@@meetingRoomSearchEquipment"
                >Equipment</label
              >
              <mat-select
                [(ngModel)]="searchParams.equipment"
                aria-labelledby="equipment"
                id="meetingroom-equipment-select"
                class="select-box select-box--no-icon"
                multiple
                i18n-placeholder
                placeholder="Select equipment...">
                <mat-option [value]="value" *ngFor="let value of searchParams.equipmentOptions">
                  {{value | splitCamelCase | translate}}
                </mat-option>
              </mat-select>
          </div>
          <div class="half-block">
            <label
              class="header-label"
              id="catering"
              for="meetingroom-catering-select"
              i18n="@@meetingRoomSearchCatering"
              >Catering</label
            >
            <select
              [(ngModel)]="searchParams.catering"
              aria-labelledby="catering"
              id="meetingroom-catering-select"
              class="select-box select-box--no-icon">
              <option [ngValue]="null" i18n="@@notSelected">Not selected</option>
              <option [ngValue]="value" *ngFor="let value of searchParams.cateringOptions">
                {{value | splitCamelCase | translate}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="half-container">
      <div class="half-block flex-column-container">
        <label i18n="@@meetingRoomAdditionalInfo" for="meeting-room-add-info" class="header-label">
          Additional Information
        </label>
        <textarea 
          class="textarea-container"
          rows="5" 
          [(ngModel)]="searchParams.additionalInformation" 
          id="meeting-room-add-info" >
          </textarea>
      </div>
    </div>
  </div>
  
  