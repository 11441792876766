import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'schemaTitle'
})
export class SchemaTitlePipe implements PipeTransform {

  transform(value: string): string {

    if (value.includes('_')) {
      value = value.split('_')[1];
    }

    return value;
  }

}
