<ng-template #rt let-r="result" let-t="term">
  {{ r }}
</ng-template>
<div id="ferrySearchForm1" class="ferry-search-form">
  <label class="header-label" i18n="@@SelectSearchOptions" id="FerrySelectSearchOptions"
    >Select search options</label
  >
  <div class="flex-row-container flex-row-container--tb-margin">
    <label class="visuallyhidden" i18n="@@SelectSearchOptions" id="FerrySelectSearchOptions"
      >Select search options</label
    >
    <mat-radio-group
      [(ngModel)]="searchParams.ferryOrEurotunnel"
      (ngModelChange)="resetAffectedFields()"
      aria-labelledby="FerrySelectSearchOptions"
      class="ferry_or_eurotunnel">
      <mat-radio-button class="light-primary" color="primary" [value]="ferryOrEurotunnel.Ferry">
        {{ ferryOrEurotunnel.Ferry | translate }}
      </mat-radio-button>
      <mat-radio-button
        class="light-primary"
        color="primary"
        [value]="ferryOrEurotunnel.Eurotunnel">
        {{ ferryOrEurotunnel.Eurotunnel | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <p class="tab_icons">
    <app-light-toggle-group tabindex="-1">
      <app-light-toggle-btn
        #onewayBtn
        [selected]="searchParams.journeyType === IrlJourneyType.SingleJourney"
        (click)="searchParams.journeyType = IrlJourneyType.SingleJourney"
        iconClass="ico-oneway"
        tabindex="-1"
        i18n="@@ferrySearchTravellingOneWay"
        >One Way Only</app-light-toggle-btn
      >
      <app-light-toggle-btn
        #returnBtn
        [selected]="searchParams.journeyType === IrlJourneyType.ReturnJourney"
        (click)="searchParams.journeyType = IrlJourneyType.ReturnJourney"
        iconClass="ico-return"
        tabindex="-1"
        i18n="@@ferrySearchTravellingReturn"
        >Return trip</app-light-toggle-btn
      >
    </app-light-toggle-group>
  </p>
  <div class="inputs-container">
    <div>
      <span i18n="@@ferrySearchTravellingOfflineMessage"
        >Please complete this form as the journey you wish to book is not available online. When you
        submit the request a member of our travel team will be in touch with a quote. Please call
        our travel team if your request is for travel within 48 hours.</span
      >
      <br />
      <span
        *ngIf="searchParams.ferryOrEurotunnel === ferryOrEurotunnel.Ferry"
        i18n="@@ferrySearchTravellingPleaseNote"
        >Please note - for any ferry pass requests please contact your offline team</span
      >
    </div>
    <div class="half-container">
      <div class="half-block">
        <label
          id="ferry-travelling-from"
          class="header-label"
          for="travelFrom1"
          i18n="@@ferrySearchTravellingFrom"
          >Travelling from:</label
        >
        <i class="ico-ferries combo-box-icon" aria-hidden="true"></i>
        <div class="flex-row-container">
          <input
            *ngIf="searchParams.ferryOrEurotunnel !== ferryOrEurotunnel.Eurotunnel"
            #tf
            autocomplete="off"
            type="text"
            id="travelFrom1"
            i18n-placeholder
            [(ngModel)]="searchParams.fromLocation"
            placeholder="Enter a location"
            onClick="this.select()"
            class="input-box"
            name="travellingFrom"
            aria-labelledby="ferry-travelling-from"
            tabindex="0"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            [ngbTypeahead]="ferryLocations" />
          <select
            *ngIf="searchParams.ferryOrEurotunnel === ferryOrEurotunnel.Eurotunnel"
            id="travelFrom1"
            [(ngModel)]="searchParams.fromLocation"
            class="combo-box combo-box--border"
            aria-labelledby="ferry-travelling-from">
            <option *ngFor="let location of searchParams?.eurotunnelLocations" [value]="location">
              {{ location }}
            </option>
          </select>
        </div>
      </div>
      <div class="half-block">
        <label
          id="ferry-travelling-to"
          class="header-label"
          for="travelFrom2"
          i18n="@@ferrySearchTravellngTo"
          >Travelling to:</label
        >
        <i class="ico-ferries combo-box-icon" aria-hidden="true"></i>
        <div class="flex-row-container">
          <input
            *ngIf="searchParams.ferryOrEurotunnel !== ferryOrEurotunnel.Eurotunnel"
            #tt
            autocomplete="off"
            type="text"
            id="travelFrom2"
            i18n-placeholder
            placeholder="Enter a location"
            onClick="this.select()"
            class="input-box"
            name="travellingTo"
            [(ngModel)]="searchParams.toLocation"
            aria-labelledby="ferry-travelling-to"
            tabindex="0"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            [ngbTypeahead]="ferryLocations" />
          <select
            *ngIf="searchParams.ferryOrEurotunnel === ferryOrEurotunnel.Eurotunnel"
            id="travelFrom2"
            [(ngModel)]="searchParams.toLocation"
            class="combo-box combo-box--border"
            aria-labelledby="ferry-travelling-to">
            <option *ngFor="let location of searchParams?.eurotunnelLocations" [value]="location">
              {{ location }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="half-container flex-start">
      <div class="half-block">
        <div class="flex-row-container">
          <ngbd-datepicker-i18n>
            <div class="flex-column-container combo-box-left">
              <label
                class="header-label date-label"
                for="ferry-outbound-date"
                i18n="@@ferrySearchOutboundDate"
                >Outbound Date (DD/MM/YYYY)</label
              >
              <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
              <app-datepicker
                id="dp1"
                [minDate]="searchParams.minOutboundDate"
                [startDate]="searchParams.outbound_date"
                [model]="searchParams.outbound_date"
                [class]="'combo-box'"
                componentId="ferry-outbound-date"
                (newDate)="searchParams.outbound_date = $event">
              </app-datepicker>
            </div>
            <div class="flex-column-container select-box-right">
              <label class="header-label" for="ferry_outbound_time" i18n="@@ferrySearchOutboundTime"
                >Outbound Time</label
              >
              <i class="ico-clock select-box-icon" aria-hidden="true"></i>
              <select
                #oft
                id="ferry_outbound_time"
                class="select-box"
                [(ngModel)]="searchParams.outbound_time"
                tabindex="0">
                <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                  {{ time.display }}
                </option>
              </select>
            </div>
          </ngbd-datepicker-i18n>
        </div>
        <div class="flex-row-container flex-row-container--tb-margin">
          <mat-radio-group
            [(ngModel)]="searchParams.departTimeType"
            class="triple-radio-buttons"
            role="">
            <mat-radio-button
              class="light-primary"
              color="primary"
              [value]="retcrit"
              *ngFor="let retcrit of searchParams.ferryTimeCritia">
              {{ retcrit | splitCamelCase | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        class="half-block ferry_return_container"
        [ngClass]="{
          ferry_return_disabled: searchParams.journeyType === IrlJourneyType.SingleJourney
        }">
        <div class="flex-row-container">
          <ngbd-datepicker-i18n>
            <div class="flex-column-container combo-box-left">
              <label
                class="header-label date-label"
                for="ferry-return-date"
                id="returndateandtime"
                i18n="@@ferrySearchReturnDate"
                >Return Date (DD/MM/YYYY)</label
              >
              <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
              <app-datepicker
                [minDate]="searchParams.minInboundDate"
                [startDate]="searchParams.return_date"
                [model]="searchParams.return_date"
                [class]="'combo-box'"
                componentId="ferry-return-date"
                [disabled]="!(searchParams.journeyType === IrlJourneyType.ReturnJourney)"
                (newDate)="searchParams.return_date = $event">
              </app-datepicker>
            </div>
            <div class="flex-column-container select-box-right">
              <i class="ico-clock select-box-icon" aria-hidden="true"></i>
              <label class="header-label" i18n="@@AriaFerryReturnDate" for="ferry_return_time"
                >Return Time</label
              >
              <select
                class="select-box"
                id="ferry_return_time"
                [disabled]="!(searchParams.journeyType === IrlJourneyType.ReturnJourney)"
                [(ngModel)]="searchParams.return_time">
                <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                  {{ time.display }}
                </option>
              </select>
            </div>
          </ngbd-datepicker-i18n>
        </div>
        <div class="flex-row-container flex-row-container--tb-margin">
          <mat-radio-group
            [(ngModel)]="searchParams.returnTimeType"
            class="triple-radio-buttons"
            role="">
            <mat-radio-button
              class="light-primary"
              color="primary"
              [value]="retcrit"
              *ngFor="let retcrit of searchParams.ferryTimeCritia">
              {{ retcrit | splitCamelCase | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="flex-column-container">
      <div class="traveller_info quarter-block">
        <div class="quarter-block offline_info_boxes">
          <div class="flex-row-container">
            <label id="ferry-ticket-type" class="container_label" i18n="@@Preferredtickettype">Preferred ticket type</label>
          </div>
          <select
            panelClass="sort-select"
            [(ngModel)]="searchParams.preferredTicketType"
            class="combo-box combo-box--no-icon combo-box--border prefType"
            name="preferredTicketType"
            aria-labelledby="ferry-ticket-type">
            <option
              *ngFor="let type of searchParams.ticketTypes"
              [value]="type">
              {{ type | splitCamelCase | translate }}
            </option>
          </select>
        </div>
      </div>
      <a
        [href]="ticketInfoLink"
        target="_blank"
        id="ferrysearch-link-ticketinformation"
        *ngIf="searchParams.ferryOrEurotunnel === ferryOrEurotunnel.Eurotunnel"
        class="ticket-info"
        i18n="@@FerrySearchEurotunnelTicketInfo"
        >Eurotunnel Ticket Information</a
      >

      <div class="flex-row-container mt-3 gap-3 ferry-user-info">
        <div class="flex-column-container">
          <label i18n="@@pnrPassDOB" for="ferry-dob">Date of Birth</label>
          <ngbd-datepicker-i18n class="ferry-dob">
            <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
            <app-datepicker
              [model]="searchParams.dob"
              [class]="'combo-box'"
              componentId="ferry-dob"
              (newDate)="searchParams.dob = $event">
            </app-datepicker>
          </ngbd-datepicker-i18n>
        </div>

        <div class="flex-column-container">
          <label i18n="@@phonenumber_title" for="ferry-phone-number">Phone Number</label>
          <i class="ico-call-phone combo-box-icon" aria-hidden="true"></i>
          <div class="flex-row-container">
            <input
              placeholder="Enter a phone number"
              class="input-box input-box--border"
              id="ferry-phone-number"
              [(ngModel)]="searchParams.phoneNumber" />
          </div>
        </div>
      </div>

      <div class="additional_info_container flex-column-mobile">
        <div class="half-container offline_info_boxes">
          <div>
            <label class="container_label">
              <div i18n="@@FerrySearchAdditionalInformation">
                Car Details - If it is a non-standard car or a van please supply dimensions in the
                additional info field.
              </div>
              <div i18n="@@FerrySearchAdditionalInformation2">
                Foot passenger only - If you are travelling without a vehicle, please enter N/A in
                the boxes below.
              </div>
            </label>
            <div class="car-details-container">
              <div class="car-details-wrapper">
                <div class="car-details-item">
                  <label id="ferry-service-class" i18n="@@FerrySearchMake" for="ferry_make">
                    Make
                  </label>
                  <input [(ngModel)]="searchParams.make" class="input-box" id="ferry_make" />
                </div>
                <div class="car-details-item">
                  <label id="ferry-service-class" i18n="@@FerrySearchModel" for="ferry_model">
                    Model
                  </label>
                  <input [(ngModel)]="searchParams.model" class="input-box" id="ferry_model" />
                </div>
                <div
                  class="car-details-item"
                  *ngIf="searchParams.ferryOrEurotunnel !== ferryOrEurotunnel.Eurotunnel">
                  <label
                    id="ferry-service-class"
                    i18n="@@FerrySearchReg"
                    for="ferry_registration_plate">
                    Registration Plate
                  </label>
                  <input
                    [(ngModel)]="searchParams.reg"
                    class="input-box"
                    id="ferry_registration_plate" />
                </div>
              </div>
              <div class="car-details-wrapper">
                <div
                  class="car-details-item"
                  *ngIf="searchParams.ferryOrEurotunnel === ferryOrEurotunnel.Eurotunnel">
                  <label
                    id="ferry-service-class"
                    i18n="@@FerrySearchReg"
                    for="ferry_registration_plate">
                    Registration Plate
                  </label>
                  <input
                    [(ngModel)]="searchParams.reg"
                    class="input-box"
                    id="ferry_registration_plate" />
                </div>
                <div
                  class="car-details-item"
                  *ngIf="searchParams.ferryOrEurotunnel !== ferryOrEurotunnel.Eurotunnel">
                  <label id="ferry-height-label" i18n="@@FerrySearchHeight" for="ferry_height">
                    Height
                  </label>
                  <input
                    [(ngModel)]="searchParams.height"
                    id="ferry_height"
                    class="input-box input-box--no-icon input-box--border" />
                </div>
                <div
                  class="car-details-item"
                  *ngIf="searchParams.ferryOrEurotunnel !== ferryOrEurotunnel.Eurotunnel">
                  <label id="ferry-length-label" i18n="@@FerrySearchLength" for="ferry_length">
                    Length
                  </label>
                  <input
                    [(ngModel)]="searchParams.length"
                    id="ferry_length"
                    class="input-box input-box--no-icon input-box--border" />
                </div>
                <div class="car-details-item">
                  <label
                    id="ferry-vehicle-type-label"
                    i18n="@@FerrySearchVehicleType"
                    for="ferry_vehicle_type"
                    >Vehicle Type
                  </label>
                  <select
                    id="ferry_vehicle_type"
                    [(ngModel)]="searchParams.vehicleType"
                    class="combo-box combo-box--no-icon combo-box--border"
                    name="vehicleType"
                    aria-labelledby="ferry-vehicle-type-label">
                    <option *ngFor="let type of searchParams?.vehicleTypes" [value]="type">
                      {{ type | splitCamelCase }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="half-block addition_instructions_container">
          <label id="ferry-service-class" i18n="@@ferrySearchInstructions" for="ferry_notes"
            >Additional Information</label
          >
          <textarea
            [(ngModel)]="searchParams.additionalInfo"
            id="ferry_notes"
            class="addition_instructions"
            placeholder="Please enter any additional information such as specific timings or special requests such as pets or insurance add-ons."
            i18n-placeholder></textarea>
        </div>
      </div>
    </div>
  </div>

  <ng-template #travellerTooltip>
    <table style="width: 500px; text-align: left">
      <tr>
        <th>Country</th>
        <th>Child</th>
        <th>Youth</th>
        <th>Senior</th>
      </tr>
      <tr>
        <td>Eurostar</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Austria</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Belgium</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Bosnia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Bulgaria</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Croatia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Denmark</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Finland</td>
        <td>6-16y</td>
        <td>17-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>France</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Germany</td>
        <td>6-14y</td>
        <td>15-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Greece</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Hungary</td>
        <td>6-13y</td>
        <td>14-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Italy</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Luxemburg</td>
        <td>6-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Macedonia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Netherlands</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Poland</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Portugal</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Romania</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Serbia</td>
        <td>4-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Slovakia</td>
        <td>6-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Slovenia</td>
        <td>6-11y</td>
        <td>12-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Spain</td>
        <td>4-13y</td>
        <td>14-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Switzerland</td>
        <td>6-15y</td>
        <td>16-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>UK</td>
        <td>6-16y</td>
        <td>17-25y</td>
        <td></td>
      </tr>
      <tr>
        <td>Australia</td>
        <td>4-15y</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>New Zealand</td>
        <td>2-14y</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>USA</td>
        <td>2-12y</td>
        <td></td>
        <td>62+</td>
      </tr>
      <tr>
        <td>Canada</td>
        <td>2-11y</td>
        <td></td>
        <td>60+</td>
      </tr>
    </table>
  </ng-template>
  <app-ferry-loading-screen></app-ferry-loading-screen>
</div>
