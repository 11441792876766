import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-cvv-capturing',
  templateUrl: './cvv-capturing.component.html',
  styleUrls: ['./cvv-capturing.component.scss']
})
export class CVVCapturingComponent {
  constructor(public activeModal: NgbActiveModal) {}

  cvv: string = '';

  onCancel(): void {
    this.activeModal.close(false);
  }

  submit() {
    this.activeModal.close(this.cvv);
  }

  stop() {
    this.activeModal.close(false);
  }

  validateCVV(cvv: string) {
    return /^\d{3,4}$/.test(cvv);
  }
}
LightningModalTypes.CVVCapturingModalComponent.component = CVVCapturingComponent;

