<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close hotel result modal"
      ngbAutoFocus
      (click)="close()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <mat-tab-group dynamicHeight [(selectedIndex)]="index" class="hotel-tabs">
      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container *ngIf="rooms?.rooms?.length; else applyingPolicy">
            <span i18n="@@itemHotelsSeeAll">See All</span>
            &nbsp;{{ rooms.rooms.length }}
            <span i18n="@@itemHotelsRates">&nbsp;Rates</span>
          </ng-container>
          <ng-template #applyingPolicy>
            <span i18n="@@itemHotelsApplyingPolicy">
              Applying policy
            </span>
          </ng-template>
        </ng-template>
        <app-rates-hotel [hotel]="hotel" [rooms]="rooms" [noOfRooms]="noOfRooms" [gmt]="gmt" [gmtSelectedRoomIndex]="gmtSelectedRoomIndex" (changeFilterChain)="changeFilterChainAction($event)" (gmtNewRoomId)="gmtSelectedRoomIndex = $event"></app-rates-hotel>
      </mat-tab>
      <mat-tab label="See Photos" i18n-label class="hotel-photos">
        <app-hotel-photos-dialog
          [hotelId]="hotelId"
          [isConferma]="isConferma"
          [hotelImages]="hotelImages"></app-hotel-photos-dialog>
      </mat-tab>
      <mat-tab label="Property Info" i18n-label>
        <app-hotel-info-dialog
          [hotelDetail]="hotel"
          [thumbUrl]="thumbUrl"
          [address]="address"
          [distanceUnit]="distanceUnit"></app-hotel-info-dialog>
      </mat-tab>
      <mat-tab label="User Review" i18n-label *ngIf="hotelRatingsInfo?.rating">
        <app-hotel-reviews [hotelRatingsInfo]="hotelRatingsInfo"></app-hotel-reviews>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="modal-footer">
    <button
      id="wellbeing-close-modal-button"
      class="small-button"
      (click)="close()"
      (keydown.enter)="close()"
      (keydown.space)="close()"
      aria-label="Close hotel result modal"
      i18n="@@hotelModalClose">
      Close
    </button>
  </div>
</div>
