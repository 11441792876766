<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@CloneTrip" id="modal-header">Clone Trip</h3>
        <button type="button" class="light-icon-button close" aria-label="Close" (click)="activeModal.close(false)" ngbAutoFocus>
            <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
            </button>
    </div>

    <div class="modal-body">
        <app-add-travellers class="clone-trip" id="add-travellers" [cloneTrip]="true" (showGuestTravellerFormChange)="showGuestTravellerForm = $event"></app-add-travellers>

        <div *ngIf="!showGuestTravellerForm">
            <mat-radio-group [(ngModel)]="searchChoice" name="searchChoice" class="flex-column-container">
                <mat-radio-button class="light-primary" color="primary" [value]="SearchChoiceType.CloneThisExactTrip" i18n="@@cloneThisExactTrip">
                    Clone this exact trip
                </mat-radio-button>
                <mat-radio-button class="light-primary" color="primary" [value]="SearchChoiceType.CloneBasketSearch" i18n="@@cloneBasketSearch">
                    Clone basket search
                </mat-radio-button>
            </mat-radio-group>
    
            <div class="basket-items" *ngIf="searchChoice === SearchChoiceType.CloneThisExactTrip">
                <div *ngFor="let basketItem of basketItems" [ngClass]="{'basket-item': basketItems.length > 1}">
                    <div [ngSwitch]="basketItem.service?.type">
                        <div *ngSwitchCase="ServiceType.Flight">
                            <app-clone-flight-date 
                                [journeyType]="basketItem.detail.journeyType" 
                                [multiDestination]="basketItem.searchQuery.multiDestination">
                            </app-clone-flight-date>
                            <app-my-bookings-basket-item-details-flight
                                [hideDate]="true"
                                [basketItem]="basketItem">
                            </app-my-bookings-basket-item-details-flight>  
                        </div>
                        <div *ngSwitchCase="ServiceType.Eurostar">
                            <app-clone-eurostar-date 
                                [journeyType]="basketItem.detail.journeyType">
                            </app-clone-eurostar-date>
                            <app-my-bookings-basket-item-details-eurostar
                            [hideDate]="true"
                            [basketItem]="basketItem">
                            </app-my-bookings-basket-item-details-eurostar>
                        </div>
                        <div *ngSwitchCase="ServiceType.Car">
                            <app-clone-car-date></app-clone-car-date>
                            <app-my-bookings-basket-item-details-car
                            [hideDate]="true"
                            [basketItem]="basketItem">
                        </app-my-bookings-basket-item-details-car>
                        </div>
                        <div *ngSwitchCase="ServiceType.Hotel">
                            <app-clone-hotel-date></app-clone-hotel-date>
                            <app-my-bookings-basket-item-details-hotel
                            [hideDate]="true"
                            [basketItem]="basketItem">
                        </app-my-bookings-basket-item-details-hotel>
                        </div>
                        <div *ngSwitchCase="ServiceType.Parking">
                            <app-clone-parking-date></app-clone-parking-date>
                            <app-my-bookings-basket-item-details-parking
                            [hideDate]="true"
                            [basketItem]="basketItem">
                        </app-my-bookings-basket-item-details-parking>
                        </div>
                        <div *ngSwitchCase="ServiceType.Lounge">
                            <app-clone-lounge-date></app-clone-lounge-date>
                            <app-my-bookings-basket-item-details-lounge
                                [hideDate]="true"
                                [basketItem]="basketItem">
                            </app-my-bookings-basket-item-details-lounge>
                        </div>
                        <div *ngSwitchCase="ServiceType.FastTrack">
                            <app-clone-fast-track-date></app-clone-fast-track-date>
                            <app-my-bookings-basket-item-details-fast-track
                                [hideDate]="true"
                                [basketItem]="basketItem">
                            </app-my-bookings-basket-item-details-fast-track>
                        </div>
                        <div *ngSwitchCase="ServiceType.Cab">
                            <app-clone-taxi-date></app-clone-taxi-date>
                            <app-my-bookings-basket-item-details-taxi
                                [hideDate]="true"
                                [basketItem]="basketItem">
                            </app-my-bookings-basket-item-details-taxi>
                        </div>
                        <div *ngSwitchCase="ServiceType.Rail">
                            <app-my-bookings-basket-item-details-rail
                                [basketItem]="basketItem"
                                [hideDate]="true"
                                [isTrainline]="isTrainline"
                                [checkInUrl]="checkInUrl">
                            </app-my-bookings-basket-item-details-rail>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            aria-labelledby="Search"
            class="small-button"
            (click)="ok()"
            (keydown.enter)="ok()"
            (keydown.space)="ok()"
            i18n-aria-label
            i18n="@@myBookingsSearch">
            Search
        </button>
    </div>
</div>
