import { Component, Input } from '@angular/core';
import {
  FlightJourney,
} from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-cloned-details',
  templateUrl: './cloned-details.component.html',
  styleUrl: './cloned-details.component.scss'
})
export class ClonedDetailsComponent {
  @Input() name: string;
  @Input() flights: FlightJourney[];
  flightNumbers: string;

  ngOnInit(): void {   
    this.flightNumbers = this.flights.map((flight: FlightJourney) => flight.marketingFlightNumber).join(', ');
  }
}
