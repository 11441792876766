<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <div [class.duplicate-item-wrapper]="duplicateBooking">
      <div *ngIf="duplicateBooking" class="duplicate-item-date">
        <i class="ico-taxi"></i>&nbsp;
        <ng-container *ngIf="taxi?.detail?.departs as departs">
          <span i18n="@@basketPanelTaxiDateTime">Date/Time:</span>
          <strong >{{ departs | sabsDate: 1 }}</strong>
          <strong >{{ departs | sabsTimePipe: 'json_time' }}</strong>
        </ng-container>
      </div>
      <div [class.duplicate-item-info]="duplicateBooking">
        <div class="cart_item_heading"
            *ngIf="taxi?.detail?.supplierName">
            <ng-container *ngIf="!duplicateBooking">
              <i class="ico-taxi"></i>&nbsp;
            </ng-container>

          <span>{{ taxi.detail.supplierName }}</span>
          <button *ngIf="!duplicateBooking"
                  class="cart_item_remove ico-trash"
                  ngbTooltip="Remove {{taxi.detail.supplierName}} from your {{(displayItinerary ? 'itinerary' : 'basket') | translate }}"
                  attr.aria-label="Remove {{taxi.detail.supplierName}} taxi from your {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                  i18n-aria-label
                  i18n-ngbTooltip
                  (click)="remove.emit()"
                  id="remove-basketitem-{{taxi.id}}"></button>
          <img *ngIf="!duplicateBooking"
              src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{ displayItinerary ?  'luggage_icon' : 'basket_move'}}.svg"
              class="move_basket_icon"
              role="button"
              ngbTooltip="Move to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
              i18n-ngbTooltip
              attr.aria-label="Move {{taxi.detail.supplierName}} taxi to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
              i18n-aria-label
              (click)="openChangeModal.emit()"
              (keydown.enter)="openChangeModal.emit()"
                (keydown.space)="openChangeModal.emit()"
                tabindex="0"
                id="share-basketitem-{{taxi.id}}">
        </div>
        <div *ngIf="taxi?.detail?.fromLocation as fromLocation">
          <span i18n="@@basketPanelTaxiFromLocation">Pick up:</span>
          <strong>{{ fromLocation?.address }} {{ fromLocation?.postcode }}</strong>
        </div>

        <div *ngIf="taxi?.detail?.toLocation as toLocation">
          <span i18n="@@basketPanelTaxiToLocation">Drop off:</span>
          <strong>{{ toLocation?.address }} {{ toLocation?.postcode }}</strong>
        </div>
        <div *ngIf="!duplicateBooking && taxi?.detail?.departs as departs">
          <span i18n="@@basketPanelTaxiDateTime">Date/Time:</span>
          <strong >{{ departs | sabsDate: 10 }}</strong>;
          <strong >{{ departs | sabsTimePipe: 'json_time' }}</strong>
        </div>
      </div>
    </div>


    <div class="car_cart_item_to_point"></div>
    <div class="cart_item_price">
      <strong *ngIf="duplicateBooking" i18n="@@basketPanelTaxiCost">Cost</strong>
      <strong>{{ taxi.price | currency: taxi.currency }}</strong>
      <em *ngIf="!duplicateBooking"  i18n="@@basketPanelTaxiTotalPrice">Total Price(Excl VAT)</em>
    </div>

  </div>
</div>
