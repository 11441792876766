import { Component, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { 
  ServiceProvider,
  ResendConfirmationEmailOption 
} from '@sabstravtech/obtservices/base'
import {
  UserService,
  BasketItem,
  ServiceType,
  HelperRoutines,
  EnterpriseSearchService
} from '@sabstravtech/obtservices/angular';

export enum SearchChoiceType {
  CloneThisExactTrip = 'Clone this exact trip',
  CloneBasketSearch = 'Clone basket search'
}
@Component({
  selector: 'app-clone-trip',
  templateUrl: './clone-trip.component.html',
  styleUrls: ['./clone-trip.component.scss']
})
export class CloneTripComponent implements OnInit {
  basketItems: BasketItem[] = [];
  ServiceType: typeof ServiceType = ServiceType;
  isTrainline: boolean = false;
  searchChoice: SearchChoiceType = SearchChoiceType.CloneThisExactTrip;
  SearchChoiceType: typeof SearchChoiceType = SearchChoiceType;
  showGuestTravellerForm: boolean = false;
  
  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService,
    private helpers: HelperRoutines,
    public searchService: EnterpriseSearchService,
  ) { }

  ngOnInit(): void {
    let isRailService = this.basketItems.find(item => item.service?.type === ServiceType.Rail);
    this.isTrainline = isRailService 
      ? this.userService.findService(isRailService.service?.id)?.name === ServiceProvider.Trainline 
      : false;
  }

  ok(): void {
    this.activeModal.close(this.searchChoice);
  }

}
LightningModalTypes.CloneTripComponent.component = CloneTripComponent;