<ng-template #rt let-r="result" let-t="term">
    {{ r.destination }} ({{ r.gateway.toUpperCase() }})
</ng-template>
<ng-container
    *ngIf="{
        terminals: searchParameters?.terminals | async,
        isArrivalLoading: isArrivalLoading | async
    } as data">
    <div class="parking-search-container">
        <div *ngIf="searchParameters?.clonedBasketItemDetails?.name">
            <app-cloned-details [name]="searchParameters?.clonedBasketItemDetails?.name"></app-cloned-details>
        </div>
        <div class="half-container">
            <div class="half-block">
                <label
                    class="header-label"
                    id="airportlabel"
                    for="airport"
                    i18n="@@ParkingSearchAirport"
                    >Airport:</label
                >
                <i class="ico-flight combo-box-icon" aria-hidden="true"></i>
                <div class="flex-row-container">
                    <input
                        #inputAirport="ngbTypeahead"
                        autocomplete="off"
                        type="text"
                        id="airport"
                        i18n-placeholder
                        placeholder="Enter an airport"
                        onClick="this.select()"
                        name="travellingFrom"
                        [inputFormatter]="formatAirports"
                        [ngbTypeahead]="getAirports"
                        [(ngModel)]="searchParameters.locationCode"
                        [resultTemplate]="rt"
                        (focus)="airportsFocus$.next($event.target.value)"
                        (click)="airportsClick$.next($event.target.value)"
                        class="select-box"
                        aria-labelledby="airportlabel"
                        (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                        (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                        [ngClass]='{"error-box": searchParameters.validationMessages?.includes("You must select airport first.")}' />
                    <i
                        *ngIf="loadingAirports"
                        class="button_spinner input-loading-spinner-left"></i>
                </div>
            </div>
            <div class="half-block">
                <div class="half-container">
                    <div class="half-block">
                        <label
                            class="header-label"
                            id="terminallabel"
                            i18n="@@ParkingSearchTerminal"
                            for="parking_terminal"
                            >Terminal</label
                        >
                        <p
                            *ngIf="data.terminals.length === 0"
                            i18n="@@ParkingSearchSelectAirportFirst">Select airport first</p>
                        <select
                            [(ngModel)]="searchParameters.terminal"
                            class="select-box terminals"
                            id="parking-select-terminal">
                            <option
                                *ngFor="let terminal of data.terminals"
                                [ngValue]="terminal.value">
                                {{ terminal.key | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="half-block">
                        <label
                            class="header-label"
                            for="Flightnumberlabel"
                            i18n="@@ParkingSearchFlightNumber"
                            >Flight number:</label
                        >
                        <input
                            class="input-box input-box--no-icon"
                            i18n-placeholder
                            placeholder="Enter flight number"
                            [(ngModel)]="searchParameters.outFlightNumber"
                            id="Flightnumberlabel" />
                    </div>
                </div>
            </div>
        </div>
        <div class="journey-panel">
            <div class="half-container">
                <div class="half-block left-block">
                    <div class="flex-row-container"
                    [ngClass]='{"error-box": searchParameters.validationMessages?.includes("Outward journey cannot be in the past.")}'>
                        <ngbd-datepicker-i18n>
                            <div class="flex-column-container combo-box-left">
                                <i
                                class="ico-calendar combo-box-icon" aria-hidden="true"
                                style="cursor: pointer"
                                ></i>
                                <label
                                class="header-label date-label"
                                i18n="@@ParkingSearchOutwardDate"
                                for="parking-search-outward-date"
                                >Outward Date (DD/MM/YYYY)</label
                                >
                                <app-datepicker
                                    [minDate]='min_date'
                                    [startDate]='searchParameters.outdate'
                                    [model]="searchParameters.outdate"
                                    [class]="'combo-box'"
                                    [componentId]="'parking-search-outward-date'"
                                    (newDate)="searchParameters.outdate = $event"
                                >
                              </app-datepicker>
                            </div>
                            <div class="flex-column-container select-box-right">
                                <label
                                    id="parking-search-outward-time"
                                    class="header-label"
                                    i18n="@@ParkingSearchOutwardTime"
                                    for="parking_outward_time"
                                    >Outward Time</label
                                >
                                <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                                <select
                                    class="select-box"
                                    [(ngModel)]="searchParameters.outtime"
                                    id="parking_outward_time">
                                    <option *ngFor="let time of timesArray" [ngValue]="time">
                                        {{ time }}
                                    </option>
                                </select>
                            </div>
                        </ngbd-datepicker-i18n>
                    </div>
                </div>
            </div>
            <div class="half-container">
                <div class="half-block right-block">
                    <div class="flex-row-container"
                    [ngClass]='{"error-box": searchParameters.validationMessages?.includes("Inward journey cannot be in the past.")}'>
                        <ngbd-datepicker-i18n>
                            <div class="flex-column-container combo-box-left">
                                <label
                                    class="header-label date-label"
                                    id="outwardjourney"
                                    for="parking-search-inward-date"
                                    i18n="@@ParkingSearchInwardJourney"
                                    >Return Date (DD/MM/YYYY)</label
                                >
                                <i
                                    class="ico-calendar combo-box-icon" aria-hidden="true"
                                    style="cursor: pointer"
                                ></i>
                                <app-datepicker
                                    [minDate]='searchParameters.outdate'
                                    [startDate]='searchParameters.indate'
                                    [model]="searchParameters.indate"
                                    [class]="'combo-box'"
                                    [componentId]="'parking-search-inward-date'"
                                    (newDate)="searchParameters.indate = $event"
                                >
                                </app-datepicker>
                            </div>
                            <div class="flex-column-container select-box-right">
                                <label
                                    id="parking-search-inward-time"
                                    class="header-label"
                                    i18n="@@ParkingSearchInwardTime"
                                    for="parking_return_time"
                                    >Return Time</label
                                >
                                <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                                <select
                                    class="select-box"
                                    [(ngModel)]="searchParameters.intime"
                                    id="parking_return_time">
                                    <option *ngFor="let time of timesArray" [ngValue]="time">
                                        {{ time }}
                                    </option>
                                </select>
                            </div>
                        </ngbd-datepicker-i18n>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<app-parking-loading-screen></app-parking-loading-screen>
