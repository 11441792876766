import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breakfast-icon',
  templateUrl: './breakfast-icon.component.html',
  styleUrl: './breakfast-icon.component.scss'
})

export class BreakfastIconComponent {
  @Input() room: any;

  hasBreakfast(description: string, roomDescription: string): boolean {
    const included = [
      'BREAKFAST INCLUDED',
      'breakfast',
      'BB',
      'B&B',
      'Bead and breakfast',
      'Bed and breakfast',
      'Bed&Break',
      'INCLUDES BREAK',
      'INCL BED AND BRE',
      'BRKFST',
      'BED AND BRKFST',
      'BRKFS'
    ];
    
    // Excluded breakfast keywords
    const excluded = [
      'breakfast not included',
      'breakfast is not included',
      "Breakfast isn't included",
      'Does not include breakfast',
      'EXCL',
      'exel Breakfast',
      'Breakfast Costs',
      'no breakfast',
    ];
    
    // Output regex patterns
    const includedPattern = '(' + included.join(')|(') + ')';
    const excludedPattern = '(' + excluded.join(')|(') + ')';
    
    // Create regular expressions
    const includedExpression = new RegExp(includedPattern, 'i');
    const excludedExpression = new RegExp(excludedPattern, 'i');

    return (
      (description.match(includedExpression) && !description.match(excludedExpression)) ||
      (roomDescription?.match(includedExpression) && !roomDescription?.match(excludedExpression))
    );
  }

}
