<div [ngClass]="{ plane: seatsReceived }">
  <ng-container *ngIf="seatKeys && !requestActive; else loadingBlock">
    <div
      *ngFor="let key of seatKeys | keyValue : true"
      class="seats-row"
      [ngClass]="'type-' + key.value?.length">
      <div
        *ngFor="let seat of key.value; let index = index; let first = first; let last = last"
        [ngClass]="seat | seatMapSpacing : first || last : aisleSeatLocation">
        <ng-container [ngTemplateOutlet]="buttonTemplate" [ngTemplateOutletContext]="{ notAvailable: isNotAvailable(seat) }"></ng-container>

        <ng-template #buttonTemplate let-notAvailable="notAvailable">
          <button
            class="seat available"
            [ngClass]="{
              selected: isSelected(seat),
              'not-available': !isSelected(seat) && (!seat.available || (seat.price && freeSeatsOnly)),
              available: seat.available,
              'no-seat': seat.price === null
            }"
            (click)="selectedSeat(seat)"
            [ngbTooltip]="tipContent"
            [disabled]="notAvailable"
            id="seat-{{ seat.row }}-{{ seat.column }}-{{ notAvailable ? 'disabled' : 'available' }}">
            @if(!seat.isFake){
              {{ seat.row }}{{ seat.column }}
              }
          </button>
        </ng-template>
        <ng-template #tipContent>
          <ng-container *ngIf="!isSelected(seat) && (!seat.available || (seat.price && freeSeatsOnly)); else availableSeatMapTooltip">
            <strong class="blocked" i18n="@@seatmap-policy-blocked">POLICY BLOCKED</strong>
          </ng-container>
          <ng-template #availableSeatMapTooltip>
            <ng-container>
              <div *ngFor="let attribute of seat.attributes; let last = last">
                {{ attribute.description }}<span *ngIf="!last">,</span>
              </div>
              <span *ngIf="seat.price">{{ seat.price }}{{ seat.currency }}</span>
              <span *ngIf="!seat.price" i18n="@@NoAdditionalCharge">No Additional Charge</span>          
            </ng-container>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div *ngIf="!seatsReceived">
      <div class="no_results_message background-10">
        <h4 class="no_results_message_heading">
          <i class="ico-info no_results_icon" i18n="@@seatMapOhNo"></i>Oh, no!
        </h4>
        <p>
          <span i18n="@@seatMapUnavailableMessage">Seat map is unavailable for this flight. </span>
        </p>
      </div>
    </div>
    <div *ngIf="seatsReceived" class="button-wrapper">
      <button
        type="button"
        class="small-button"
        (click)="clearSelectedSeat()"
        i18n="@@clear-selection"
        id="clear-selection-button"
        [disabled]="!localSelectedSeat">
        Clear Selection
      </button>
    </div>
  </ng-container>

  <ng-template #loadingBlock>
    <div class="loadingBlock">
      <i class="button_spinner"></i>
    </div>
  </ng-template>
</div>
