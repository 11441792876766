<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@PropertyRequiresCVV" id="modal-header">
            The property you are booking requires a CVV.
        </h3>
        <button type="button" class="close" aria-label="Close" ngbAutoFocus (click)="onCancel()">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body modal_body_ticket">
        <div class="card-cvv-container">
            <div class="cvv-wrapper">
                <span i18n="@@CardCVV">Card CVV</span>:
                <div class="asterisk">*</div>
            </div>
            <div class="input-wrapper">
                <input [(ngModel)]="cvv" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="button_stop_booking" class="small-button small-button--close"
            aria-label="Stop Booking" i18n-aria-label aria-live="false" (click)="stop()" (keydown.enter)="stop()"
            (keydown.space)="stop()" i18n="@@StopBooking">
            Stop Booking
        </button>
        <button type="button" id="button_continue" class="small-button" aria-label="Continue" i18n-aria-label
            aria-live="false" [disabled]="!validateCVV(cvv)" (click)="submit()" (keydown.enter)="submit()"
            (keydown.space)="submit()" i18n="@@Continue">
            Continue
        </button>
    </div>
</div>