<!-- Fast Track -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <div [class.duplicate-item-wrapper]="duplicateBooking">
      <div *ngIf="duplicateBooking" class="duplicate-item-date">
        <i class="ico-fast-track ico-material"></i>&nbsp;
        <span i18n="@@basketPanelCheckInFastTrack">Check-In:</span>
        <strong>{{ fastTrack.detail.arrivalDatetime | sabsDate: 5 }}</strong>
      </div> 
      <div [class.duplicate-item-info]="duplicateBooking">
        <div class="cart_item_heading">
          <ng-container *ngIf="!duplicateBooking">
            <i class="ico-fast-track ico-material"></i>&nbsp;
          </ng-container>
          <span>{{ fastTrack.detail.name }}</span>
          <button *ngIf="!duplicateBooking" 
                  class="cart_item_remove ico-trash"
                  ngbTooltip="Remove {{fastTrack.detail.name}} from your {{(displayItinerary ? 'itinerary' : 'basket')| translate}}"
                  attr.aria-label="Remove {{fastTrack.detail.name}} fast track from your {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                  i18n-ngbTooltip
                  i18n-aria-label
                  (click)="remove.emit()"
                  id="remove-basketitem-{{fastTrack.id}}"></button>
          <img *ngIf="!duplicateBooking" 
                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{ displayItinerary ?  'luggage_icon' : 'basket_move'}}.svg"
                class="move_basket_icon"
                role="button"
                ngbTooltip="Move to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                i18n-ngbTooltip
                attr.aria-label="Move {{fastTrack.detail.name}} fast track to different {{(displayItinerary ? 'itinerary' : 'basket') | translate}}"
                i18n-aria-label
                (click)="openChangeModal.emit()"
                (keydown.enter)="openChangeModal.emit()"
            (keydown.space)="openChangeModal.emit()"
            tabindex="0"
            id="share-basketitem-{{fastTrack.id}}">
        </div>
        <div class="cart_item_destination"
             id="fast-track-{{displayItinerary ? 'itinerary' : 'basket'}}-dest-{{itemIndex}}"
              *ngIf='fastTrack.searchQuery.locationCode'>
            {{ fastTrack.searchQuery.airportFullName 
              ? fastTrack.searchQuery.airportFullName + ' (' + fastTrack.searchQuery.locationCode + ')'
              : fastTrack.searchQuery.locationCode | airportIata | async 
            }}
        </div>
      </div>
    </div>
    <div *ngIf="!duplicateBooking" class="check_in">
        <span i18n="@@basketPanelCheckInFastTrack">Check-In:</span>
        <strong>{{ fastTrack.detail.arrivalDatetime | sabsDate : 5 }}</strong>
    </div>
    <div class="car_cart_item_to_point"></div>
    <div class="cart_item_price">
      <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostFastTrack">Cost</strong>
        <strong>{{ fastTrack.price | currency: fastTrack.currency}}</strong>
        <em *ngIf="!duplicateBooking" i18n="@@basketPanelTotalPriceFastTrack">Total Price</em>
    </div>
  </div>
</div>
