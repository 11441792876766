@if (bannerImage && showBanner) {
    <div id="banner_container" class="banner-container" >
        <div class="info-container">
                <i aria-hidden="true" class='ico ico-info-circle info-icon'></i>
        </div>
        <div id="banner_image_container" class="banner-image-container">

            <img alt="" id="banner_image" class="banner-image" [src]="bannerImage.changingThisBreaksApplicationSecurity">

        </div>
        <div class="close-container">

            <button type="button"
                    class="close"
                    ngbAutoFocus
                    aria-label="Hide Banner"
                    (click)="close()"
                    (keydown.enter)="close()"
                    (keydown.space)="close()"
                    >
                <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
            </button>

        </div>
    </div>
}
